import { call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { setNews, setNewsLoadingState } from './actionCreators';
import { LoadingStatus } from '../../../types/common';
import { NewsApi } from '../../../services/api/news-service/newsApi';
import { FetchNewsActionInterface, NewsActionsType } from './contracts/actionTypes';
import { NewsResponseInterface } from '../../../types/news';

export function* fetchNewsArticle({ payload }: FetchNewsActionInterface) {
    try {
        yield put(setNewsLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<NewsResponseInterface> = yield call(NewsApi.fetchNews, payload.page, payload.size, payload.categories);
        yield put(setNews({
            items: response.data.newsData,
            pagesCount: response.data.pageSize
        }));
    } catch (error) {
        yield put(setNewsLoadingState(LoadingStatus.ERROR));
    }
}

export function* newsSaga() {
    yield takeLatest(NewsActionsType.GET_NEWS, fetchNewsArticle);
}
import { Divider } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { CompatClient, Stomp } from "@stomp/stompjs";
import React, { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import SockJS from "sockjs-client";

import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ShareTweetIconButton from "../../components/ShareTweetIconButton/ShareTweetIconButton";
import Spinner from "../../components/Spinner/Spinner";
import TweetComponent from "../../components/TweetComponent/TweetComponent";
import TweetComponentActions from "../../components/TweetComponentActions/TweetComponentActions";
import VeracityIconButton from "../../components/VeracityIconButton/VeracityIconButton";
import { APP_NAME } from '../../constants/common-constants';
import { WS_URL } from "../../constants/endpoint-constants";
import { TOPIC_TWEET, TOPIC_TWEET_VOTE } from "../../constants/ws-constants";
import {
  fetchReplies,
  fetchTweetData,
  resetRepliesState,
  resetTweetState,
  setVoteData,
  updateTweetData
} from "../../store/ducks/tweet/actionCreators";
import {
  selectIsRepliesLoading,
  selectIsTweetError,
  selectIsTweetLoadedSuccess,
  selectIsTweetLoading,
  selectLikesCount,
  selectReplies,
  selectRepliesCount,
  selectRetweetsCount,
  selectTweetAuthorAvatar,
  selectTweetAuthorFullName,
  selectTweetAuthorId,
  selectTweetId,
  selectTweetText
} from "../../store/ducks/tweet/selectors";
import { selectDeleteTweetId } from '../../store/ducks/tweets/selectors';
import { selectUserVeracityScore } from '../../store/ducks/user/selectors';
import { useGlobalStyles } from "../../util/globalClasses";
import { textFormatter } from "../../util/text-formatter";
import AddReplyToTweet from "./AddReplyToTweet/AddReplyToTweet";
import { useFullTweetStyles } from "./FullTweetStyles";
import LikeIconButton from "./LikeIconButton/LikeIconButton";
import ReplyIconButton from "./ReplyIconButton/ReplyIconButton";
import RetweetIconButton from "./RetweetIconButton/RetweetIconButton";
import TweetActions from "./TweetActions/TweetActions";
import TweetDateTime from "./TweetDateTime/TweetDateTime";
import TweetErrorPage from "./TweetErrorPage/TweetErrorPage";
import TweetGif from "./TweetGif/TweetGif";
import TweetHeader from "./TweetHeader/TweetHeader";
import TweetImage from "./TweetImage/TweetImage";
import TweetInteractionCount from "./TweetInteractionCount/TweetInteractionCount";
import TweetList from "./TweetList/TweetList";
import TweetMedia from "./TweetMedia/TweetMedia";
import TweetPoll from "./TweetPoll/TweetPoll";
import TweetQuote from "./TweetQuote/TweetQuote";
import TweetReplyInfo from "./TweetReplyInfo/TweetReplyInfo";

let stompClient: CompatClient | null = null;

const FullTweet = (): ReactElement | null => {
    const history = useHistory();
    const globalClasses = useGlobalStyles({});
    const classes = useFullTweetStyles();
    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();
    const veracityScore = useSelector(selectUserVeracityScore);
    const tweetId = useSelector(selectTweetId);
    const tweetText = useSelector(selectTweetText);
    const isTweetLoading = useSelector(selectIsTweetLoading);
    const isTweetLoadedSuccess = useSelector(selectIsTweetLoadedSuccess);
    const isError = useSelector(selectIsTweetError);
    const tweetAuthorFullName = useSelector(selectTweetAuthorFullName);
    const replies = useSelector(selectReplies);
    const isRepliesLoading = useSelector(selectIsRepliesLoading);
    const deletedTweetIds = useSelector(selectDeleteTweetId);
    const tweetAuthorId = useSelector(selectTweetAuthorId);
    const tweetAuthorAvatar = useSelector(selectTweetAuthorAvatar);
    const tweetRepliesCount = useSelector(selectRepliesCount);
    const tweetRetweetsCount = useSelector(selectRetweetsCount);
    const tweetLikesCount = useSelector(selectLikesCount);

    const isTweetDeleted = React.useMemo(() => (deletedTweetIds.includes(Number(tweetId))), [deletedTweetIds, tweetId]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (params.id) {
            dispatch(fetchTweetData(parseInt(params.id)));

            stompClient = Stomp.over(() => new SockJS(WS_URL));
            stompClient.connect({}, () => {
                stompClient?.subscribe(TOPIC_TWEET(params.id), (response) => {
                    dispatch(updateTweetData(JSON.parse(response.body)));
                });

                stompClient?.subscribe(TOPIC_TWEET_VOTE(params.id), (response) => {

                    dispatch(setVoteData(JSON.parse(response.body)));
                });
            });
        }

        return () => {
            stompClient?.disconnect();
            dispatch(resetTweetState());
        };
    }, [params.id]);

    useEffect(() => {
        if (isTweetLoadedSuccess) {
            dispatch(fetchReplies(parseInt(params.id)));
            document.title = `${tweetAuthorFullName} on ${APP_NAME}: "${tweetText}"`;
        }
        return () => {
            dispatch(resetRepliesState());
        };
    }, [isTweetLoadedSuccess]);

    useEffect(() => {
        if (!tweetId && isError) {
            history.goBack();
        }
    }, [tweetId, isError]);

    if (isTweetLoading) {
        return <Spinner paddingTop={200} />;
    } else if (tweetId && isTweetLoadedSuccess) {
        return (
            <PageWrapper title={"Tweet"}>
                <div className={globalClasses.contentWrapper}>
                    <Paper className={classes.container}>
                        {isTweetDeleted && (
                            <div className={classes.blurContainer}>
                                <Typography variant={"h3"} className={classes.textBlurContainer}>
                                    This tweet has been deleted.
                                </Typography>
                            </div>
                        )}
                        <TweetActions />
                        <div className={classes.tweetHeader}>
                            <TweetHeader />
                            <TweetComponentActions tweetId={tweetId} isFullTweet />
                        </div>
                        {/* <Typography variant={'h4'} color='error' style={{ fontSize: '14px' }}>
                            {isTweetDeleted && textFormatter(`This tweet is deleted`)}
                        </Typography> */}
                        <Typography variant={"h3"} className={classes.textWrapper}>
                            {textFormatter(tweetText!)}
                            <TweetMedia />
                            <TweetImage />
                            <TweetGif />
                            <TweetPoll />
                            <TweetQuote />
                            <TweetList />
                        </Typography>
                        <TweetDateTime />
                        <TweetInteractionCount />
                        <div className={classes.info}>
                            {veracityScore === 1 && 
                             (<VeracityIconButton 
                                tweetId={tweetId!}
                                text={tweetText!}
                                userId={tweetAuthorId!}
                                userAvatar={tweetAuthorAvatar}
                              />)
                            }
                            <ReplyIconButton
                              repliesCount={tweetRepliesCount}
                            />
                            <RetweetIconButton
                              retweetsCount={tweetRetweetsCount}
                            />
                            <LikeIconButton
                              likesCount={tweetLikesCount}
                            />
                            <ShareTweetIconButton tweetId={tweetId!} isFullTweet />
                        </div>
                        <Divider />
                        <TweetReplyInfo />
                        <AddReplyToTweet />
                    </Paper>
                    <div className={classes.divider} />
                    {isRepliesLoading ? (
                        <Spinner />
                    ) : (
                        replies?.map((tweet) => <TweetComponent key={tweet.id} tweet={tweet} />)
                    )}
                </div>
            </PageWrapper>
        );
    } else if (!tweetId && isError) {
        return <TweetErrorPage />;
    } else {
        return null;
    }
};

export default FullTweet;

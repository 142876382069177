import {
    FetchUserMentionsActionInterface,
    FetchUsersMentionsListActionInterface,
    ResetUsersStateActionInterface,
    SetMentionUserDetailActionInterface,
    SetUsersMentionsListActionInterface,
    SetUsersMentionsListLoadingStatusActionInterface,
    UsersMentionsActionsType
} from "./contracts/actionTypes";
import {
    UserMentionListRequest, UsersMentionsState,
} from "./contracts/state";

export const setUsersMentionsList = (payload: UsersMentionsState["users"]): SetUsersMentionsListActionInterface => ({
    type: UsersMentionsActionsType.SET_USERS,
    payload
});

export const resetUsersMentionsState = (): ResetUsersStateActionInterface => ({
    type: UsersMentionsActionsType.RESET_USERS_STATE
});

export const setUsersMentionsListLoadingState = (payload: UsersMentionsState["loadingState"]): SetUsersMentionsListLoadingStatusActionInterface => ({
    type: UsersMentionsActionsType.SET_USERS_LOADING_STATE,
    payload
});

export const fetchUserMentionLists = (payload: UserMentionListRequest): FetchUsersMentionsListActionInterface => ({
    type: UsersMentionsActionsType.FETCH_USER_MENTION_LIST,
    payload
})

export const fetchMentionUser = (payload: UserMentionListRequest): FetchUserMentionsActionInterface => ({
    type: UsersMentionsActionsType.FETCH_MENTION_USER_DETAIL,
    payload
})

export const setMentionUserDeail = (payload: UsersMentionsState["users"]): SetMentionUserDetailActionInterface => ({
    type: UsersMentionsActionsType.SET_MENTION_USER_DETAIL,
    payload
})
import produce, { Draft } from 'immer';
import { NewsActions, NewsActionsType } from './contracts/actionTypes';
import { LoadingStatus } from '../../../types/common';
import { NewsState } from './contracts/state';

const initState: NewsState = {
    news: [],
    pagesCount: 0,
    loadingState: LoadingStatus.LOADING
}
export const newsReducer = produce((draft: Draft<NewsState>, action: NewsActions) => {
    switch (action.type) {
        case NewsActionsType.SET_NEWS:
            draft.news = [...draft.news, ...action.payload.items];
            draft.pagesCount = action.payload.pagesCount;
            draft.loadingState = LoadingStatus.LOADED;
            break;
        case NewsActionsType.RESET_NEWS:
            draft.news = [];
            draft.pagesCount = 0;
            break;
        case NewsActionsType.SET_LOADING_STATE:
            draft.loadingState = action.payload;
            break;
        default:
            break;
    }
 }, initState);
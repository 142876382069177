
export const userNameValidator = (value: string) => {
  const regex = /^[a-zA-Z0-9_]{4,}$/;
  if (value === "@") {
    return "Username cannot be '@'";
  }
  if (!regex.test(value)) {
    return "Username must be at least 4 characters long and can only contain letters, numbers, and underscores";
  }
  const usernamePattern = /^(?!.*_{2,})[_A-Za-z0-9]+$/;
  if (!usernamePattern.test(value)) {
    return "Please enter valid username";
  }
  if (value.length > 15) {
    return "Username must be shorter than 15 characters";
  }
  return null;
};
import React, { ComponentType, useEffect } from "react";
import { APP_NAME } from '../constants/common-constants';

export const withDocumentTitle = <T extends object>(Component: ComponentType<T>) => (title?: string) => (props: T) => {

    useEffect(() => {
        document.title = title ? `${title} | ${APP_NAME}` : APP_NAME;
    }, []);

    return <Component {...props as T} />;
};

import { Divider, Radio, Switch, Typography } from "@material-ui/core";
import { CheckCircle, RadioButtonUnchecked } from "@material-ui/icons";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { withDocumentTitle } from "../../../../../hoc/withDocumentTitle";
import { updatePhotoTagging } from '../../../../../store/ducks/user/actionCreators';
import { selectPhotoTaggingEnabled, selectWhoCanTagInPhoto } from '../../../../../store/ducks/user/selectors';
import { PHOTO_TAGGING } from '../../../../../types/common';
import { useGlobalStyles } from "../../../../../util/globalClasses";
import { usePhotoTaggingStyles } from "./PhotoTaggingStyles";

const PhotoTagging: FC = (): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const classes = usePhotoTaggingStyles();
    const dispatch = useDispatch();
    const photoTaggingEnabled = useSelector(selectPhotoTaggingEnabled);
    const whoCanTagInPhoto = useSelector(selectWhoCanTagInPhoto);
    const [selectedValue, setSelectedValue] = useState<string>(PHOTO_TAGGING.EVERYONE);
    const [photoTaggingValue, setPhotoTaggingValue] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSelectedValue(event.target.value);
        dispatch(updatePhotoTagging({ isPhotoTaggingEnabled: true, whoCanTagInPhoto: event.target.value }));
    };

    useEffect(() => {
      if (typeof photoTaggingEnabled !== "undefined") {
        setPhotoTaggingValue(photoTaggingEnabled);
      }
      if (whoCanTagInPhoto) {
        setSelectedValue(whoCanTagInPhoto);
      }
    }, [photoTaggingEnabled, whoCanTagInPhoto])

    const handlePhotoTaggingChange = (event: { target: { checked: boolean; }; }) => {
      dispatch(updatePhotoTagging({ isPhotoTaggingEnabled: event.target.checked, whoCanTagInPhoto: PHOTO_TAGGING.EVERYONE }));
    };

    return (
        <>
            <div className={globalClasses.itemInfoWrapper}>
                <Typography variant={"h6"} component={"div"} className={classes.title}>
                    Photo tagging
                    <span className={classes.switch}>
                        <Switch
                          checked={photoTaggingValue}
                          onChange={handlePhotoTaggingChange}
                          inputProps={{ 'aria-label': 'controlled switch' }}
                        />
                    </span>
                </Typography>
                <Typography variant={"subtitle2"} component={"div"}>
                    Allow people to tag you in their photos and receive notifications when they do so.
                </Typography>
            </div>
            {photoTaggingEnabled && (
              <>
                <Divider />
                <div className={globalClasses.itemInfoWrapper}>
                    <div className={globalClasses.infoItemRadioCheckbox}>
                        <Typography variant={"body1"} component={"span"}>
                            Anyone can tag you
                        </Typography>
                        <Radio
                            checked={selectedValue === PHOTO_TAGGING.EVERYONE}
                            onChange={handleChange}
                            value={PHOTO_TAGGING.EVERYONE}
                            name="radio-buttons"
                            inputProps={{ "aria-label": PHOTO_TAGGING.EVERYONE }}
                            icon={<RadioButtonUnchecked color={"primary"} />}
                            checkedIcon={<CheckCircle color={"primary"} />}
                            size="small"
                        />
                    </div>
                    <div className={globalClasses.infoItemRadioCheckbox}>
                        <Typography variant={"body1"} component={"span"}>
                            Only people you follow can tag you
                        </Typography>
                        <Radio
                            checked={selectedValue === PHOTO_TAGGING.FOLLOWERS}
                            onChange={handleChange}
                            value={PHOTO_TAGGING.FOLLOWERS}
                            name="radio-buttons"
                            inputProps={{ "aria-label": PHOTO_TAGGING.FOLLOWERS }}
                            icon={<RadioButtonUnchecked color={"primary"} />}
                            checkedIcon={<CheckCircle color={"primary"} />}
                            size="small"
                        />
                    </div>
                </div>
              </>
            )}
        </>
    );
};

export default withDocumentTitle(PhotoTagging)("Photo tagging");

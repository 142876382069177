import { LoadingStatus } from '../../../types/common';
import { FetchNewsActionInterface, NewsActionsType, ResetNewsActionInterface, SetNewsLoadingStateActionInterface } from './contracts/actionTypes';
import { FetchNewsRequestState } from './contracts/state';

export const setNews = (payload: any): any => ({
    type: NewsActionsType.SET_NEWS,
    payload
})

export const setNewsLoadingState = (payload: LoadingStatus): SetNewsLoadingStateActionInterface => ({
    type: NewsActionsType.SET_LOADING_STATE,
    payload
});

export const fetchNews = (payload: Partial<FetchNewsRequestState>): FetchNewsActionInterface => ({
    type: NewsActionsType.GET_NEWS,
    payload
});

export const resetNews = (): ResetNewsActionInterface => ({
    type: NewsActionsType.RESET_NEWS
})
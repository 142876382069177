import { Paper, Typography } from "@material-ui/core";
import { useTrendingComponentStyle } from "./TrendingComponentStyle";
import { textFormatter } from "../../util/text-formatter";
import { TagResponse } from "../../types/tag";

interface IProps {
    trend: TagResponse;
}

const TrendingComponent = ({ trend }: IProps) => {
    const classes = useTrendingComponentStyle();
    return (
        <Paper className={classes.container}>
            {textFormatter(trend.tagName)}
            <Typography variant="subtitle1" className={classes.subTitle}>
                {trend.tweetsQuantity} Tweets
            </Typography>
        </Paper>
    );
};

export default TrendingComponent;

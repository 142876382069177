import { makeStyles } from '@material-ui/core';

export const useVeracityModalStyles = makeStyles(() => ({
    title: {
        marginTop: 20
    },
    subtitle: {
        marginTop: 15,
        marginBottom: 20
    },
    text: {
        maxWidth: 450,
        display: "inline-block",
        marginBottom: 20
    },
    radioVaracity: {
        "& svg": {
            width: 16,
            height: 16
        }
    },
}))
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Divider, FormControl, IconButton, InputBase, Paper, Typography } from "@material-ui/core";
import { PaperPlaneIcon } from "../../icons";
import { useLaunchSoontyles } from "./LaunchSoonStyles";
import { useDispatch, useSelector } from "react-redux";
import { preProcessRequests } from "../../store/ducks/launchSoon/actionCreators";
import { useEffect, useReducer } from "react";
import { FormState } from "../../store/ducks/launchSoon/contracts/state";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { selectCommon } from '../../store/ducks/common/selectors';
import { setCommonStatus } from '../../store/ducks/common/actionCreators';
import Spinner from '../../components/Spinner/Spinner';

export interface LaunchSoonFormProps {
    email: string;
}

const LaunchSoonFormSchema = yup.object().shape({
    email: yup
    .string()
    .required("Please enter a valid email.")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Invalid email")
});

const LaunchSoonForm = ({
    coordinate
}: {
    coordinate: {
        lat: number | string;
        long: number | string;
    };
}) => {
    const classes = useLaunchSoontyles();
    const disptch = useDispatch();
    const commonStatus = useSelector(selectCommon);
    const {
        control,
        handleSubmit,
        clearErrors,
        formState: { errors }
    } = useForm<LaunchSoonFormProps>({
        resolver: yupResolver(LaunchSoonFormSchema)
    });

    const [state, setState] = useReducer(
        (prev: FormState, next: Partial<FormState>) => ({
            ...prev,
            ...next
        }),
        {
            hideInput: false,
            submitting: false
        }
    );
    const { executeRecaptcha } = useGoogleReCaptcha();

    const onSubmit = async (data: LaunchSoonFormProps): Promise<void | boolean> => {
        if (state.submitting) return false;
        setState({ submitting: true });

        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }

        // Trigger the reCAPTCHA v3 challenge
        const token = await executeRecaptcha();

        // Clear previous errors
        clearErrors();

        const payload = {
            email: data.email,
            recaptchaToken: token,
            latitude: coordinate.lat,
            longitude: coordinate.long
        };

        disptch(preProcessRequests(payload));
    };

    useEffect(() => {
      if (commonStatus?.isSuccess) {
        setState({ submitting: false, hideInput: true });
        disptch(setCommonStatus({...commonStatus, isSuccess: false }));
      }
      if (commonStatus?.isFail) {
        setState({ submitting: false, hideInput: false });
        disptch(setCommonStatus({...commonStatus, isFail: false }));
      }
    }, [commonStatus, disptch])

    return (
        <>
            {!state.hideInput ? (
                <>
                    <Paper component="form" onSubmit={handleSubmit(onSubmit)} className={classes.papper}>
                        <FormControl className={classes.formContorl}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                render={({
                                    field: { onChange, value }
                                }) => (
                                    <InputBase
                                        className={classes.inputBase}
                                        name="email"
                                        placeholder="Enter your email"
                                        inputProps={{ "aria-label": "Enter your email" }}
                                        disabled={state.submitting}
                                        error={!!errors.email}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />

                            <Divider className={classes.devider} orientation="vertical" />
                            <IconButton
                                color="primary"
                                type="submit"
                                disabled={state.submitting}
                                className={classes.paperPlane}
                                aria-label="directions"
                            >
                                {commonStatus.isLoading || state.submitting ? (<Spinner paddingTop={0} paddingBottom={0}/>) : PaperPlaneIcon }
                            </IconButton>
                        </FormControl>
                    </Paper>
                    {errors.email && (
                        <Typography color="error" variant="caption" style={{fontSize: "16px"}}>
                            {errors.email.message}
                        </Typography>
                    )}
                </>
            ) : (
                <Typography variant="h5">Thank you for subscribing! We will let you know when it launches</Typography>
            )}
        </>
    );
};

export default LaunchSoonForm;

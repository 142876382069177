import { FC, ReactElement, useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useLaunchSoontyles } from "./LaunchSoonStyles";
import { AppIcon, BlueBirdIcon, CloudCenterIcon, CloudLeftIcon, CloudRightIcon } from "../../icons";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { withDocumentTitle } from '../../hoc/withDocumentTitle';
import LaunchSoonForm from './LaunchSoonForm';
import { APP_NAME, GOOGLE_RECAPTCHA_KEY } from '../../constants/common-constants';
import { PRODUCTION_URL } from '../../constants/url-constants';
import { useSelector } from 'react-redux';
import { selectCommon } from '../../store/ducks/common/selectors';

const LaunchSoon: FC = (): ReactElement => {
    const classes = useLaunchSoontyles();
    const commonStatus = useSelector(selectCommon);
    const year = new Date().getFullYear();

    const [isSuccess, setIsSuccess] = useState(false);
    const [coordinate, setCoordinate] = useState({
        lat: 0,
        long: 0
    });

    useEffect(() => {
        window.navigator.geolocation.getCurrentPosition((position) => {
            setCoordinate({
                lat: position.coords.latitude,
                long: position.coords.longitude
            })
        }, (error) => {
            console.error(error);
            // dispatch(setOpenSnackBar("Please allow location"));
        })
    }, []);

    useEffect(() => {
        if (commonStatus.isSuccess) {
            setIsSuccess(true)
        }
    }, [commonStatus])
    return (
        <div style={{ width: "100%" }} className={classes.body}>
            <div className={classes.header}>
                <div className={classes.headerIconTopLeft}>{CloudLeftIcon}</div>
                <div className={classes.headerIconCenter}>{CloudCenterIcon}</div>
                <div className={classes.headerIconRight}>{CloudRightIcon}</div>
            </div>
            <div className={classes.container}>
                <div className={classes.blueBirdIcon}>
                    <AppIcon color="primary" width="179" height="148" />
                    <Typography variant="h4" className={classes.heading}>
                        {APP_NAME}
                    </Typography>
                </div>
                <div className={classes.contentBody}>
                    <Typography variant="h3" className={classes.bodyHeading}>We're launching soon!</Typography>
                    {!isSuccess && (
                        <Typography variant="subtitle1" className={classes.subTitle}>
                            Sign up below to get early access to our beta version and stay updated.
                        </Typography>
                    )}
                </div>
                <div className={classes.contentBody}>
                    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
                        <LaunchSoonForm coordinate={coordinate} />
                    </GoogleReCaptchaProvider>
                </div>
            </div>
            <div className={classes.footer}>
                <Typography variant="subtitle2">
                    &copy;&nbsp;{year}&nbsp;
                    <Link to={{ pathname: PRODUCTION_URL }} className={classes.copyright} target="_blank">
                        {APP_NAME}
                    </Link>
                </Typography>
            </div>
        </div>
    );
};

export default withDocumentTitle(LaunchSoon)('Launching Soon');

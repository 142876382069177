import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchMentionUser, resetUsersMentionsState } from '../../../store/ducks/usersMentions/actionCreators';
import { selectMentionUserDetail } from '../../../store/ducks/usersMentions/selectors';
import { PROFILE } from '../../../constants/path-constants';

const MentionLink = ({ match }: {match: string}) => {
    const dispatch = useDispatch();
    const userDetail = useSelector(selectMentionUserDetail);
    const history = useHistory();
    const handleHover = (event: any) => {
        event.stopPropagation(); // Prevent the click event from bubbling up
        event.preventDefault(); // Prevent the default link behavior
        dispatch(fetchMentionUser({ username: match }))
    }

    useEffect(() => {
        if (userDetail.length) {
            const user = userDetail.find((user) => user.username === match.replace("@", ""));
            if (user) {
                history.push(`${PROFILE}/${user?.id}`);
            }

            return () => {
                dispatch(resetUsersMentionsState());
            }
        }
    }, [userDetail, history])
    
    return (
        <span
            id="mention"
            onClick={(event) => handleHover(event)}
        >
            {match}
        </span>
    )
}

export default MentionLink;
import { useEffect, useState } from "react";
import { Switch, Typography } from "@material-ui/core";
import { withDocumentTitle } from "../../../../hoc/withDocumentTitle";
import { useGlobalStyles } from "../../../../util/globalClasses";
import { useVeracityEngineStyles } from "./VeracityEngineStyles";
import { useDispatch, useSelector } from "react-redux";
import { selectUserVeracityScore } from "../../../../store/ducks/user/selectors";
import { updateVeracityScore } from "../../../../store/ducks/user/actionCreators";

const VeracityEngine = () => {
    const globalClasses = useGlobalStyles({});
    const classes = useVeracityEngineStyles();
    const dispatch = useDispatch();
    const veracityScore = useSelector(selectUserVeracityScore);
    const [veracity, setVeracity] = useState<number | undefined>(veracityScore);
    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        setVeracity(veracityScore);
        if (veracityScore) {
            setChecked(veracityScore !== 0);
        }
    }, [veracityScore]);

    const handleUpdateVerascity = (e: any) => {
        setChecked(e.target.checked);
        setVeracity(e.target.checked ? 1 : 0);
        dispatch(updateVeracityScore(e.target.checked ? 1 : 0));
    };

    return (
        <div className={globalClasses.itemInfoWrapper}>
            <Typography variant={"h6"} component={"div"} className={classes.title}>
                Veracity Engine{" "}
                <span className={classes.switch}>
                    <Switch
                        defaultChecked={veracity !== 0}
                        checked={checked}
                        defaultValue={veracity}
                        value={1}
                        onChange={handleUpdateVerascity}
                    />
                </span>
            </Typography>
            {/* <Typography variant={"subtitle2"} component={"div"}>
                Allow people to tag you in their photos and receive notifications when they do so.
            </Typography> */}
        </div>
    );
};

export default withDocumentTitle(VeracityEngine)("Veracity Engine");

import { useDispatch, useSelector } from "react-redux";
import { History, LocationState } from "history";
import { selectRegistrationStep6 } from "../../../store/ducks/authentication/selector";
import DialogWrapper from "../DialogWrapper/DialogWrapper";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import * as yup from "yup";
import { useVeracityModalStyles } from "./VeracityModalStyles";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { fetchVeracityScore } from '../../../store/ducks/user/actionCreators';
import { useHistory } from 'react-router-dom';

interface VeracityFormProps {
    veracityScore: string;
}

export interface VeracityScoreRequest {
    veracityScore: number;
    history: History<LocationState>;
}

const SetVeracityFormSchema = yup.object().shape({
    veracityScore: yup.string().required()
});

const VeracityModal = () => {
    const classes = useVeracityModalStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const registrationStep6 = useSelector(selectRegistrationStep6);
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<VeracityFormProps>({
        resolver: yupResolver(SetVeracityFormSchema),
        mode: "onChange"
    });

    const onOpenSetVeracityScore = (data: VeracityFormProps) => {
        // debugger
        dispatch(fetchVeracityScore({ veracityScore: parseInt(data.veracityScore), history }))
    };

    return (
        <DialogWrapper
            isOpen={registrationStep6}
            onClick={handleSubmit(onOpenSetVeracityScore)}
            disabledButton={!watch("veracityScore")}
        >
            <Typography component={"div"} className={classes.title}>
                Step 6 of 6
            </Typography>

            <Typography variant={"h3"} component={"div"} className={classes.title}>
                Customize your preferences
            </Typography>
            <Typography component={"div"} className={classes.subtitle}>
                Manage your tweet listing accordingly.
            </Typography>
            <FormControl component="fieldset" fullWidth>
                <Controller
                    control={control}
                    name="veracityScore"
                    defaultValue=''
                    render={({ field }) => (
                        <>
                            <RadioGroup {...field}>
                                {/* Option 1 */}
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant={"subtitle1"} component={"span"} className={classes.text}>
                                        OFF
                                    </Typography>
                                    <FormControlLabel
                                        value="0"
                                        control={<Radio color="primary" className={classes.radioVaracity} />}
                                        label=""
                                    />
                                </Box>

                                {/* Option 2 */}
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant={"subtitle1"} component={"span"} className={classes.text}>
                                        Post Verification
                                    </Typography>
                                    <FormControlLabel
                                        value="1"
                                        control={<Radio color="primary" className={classes.radioVaracity} />}
                                        label=""
                                    />
                                </Box>

                                {/* Option 3 */}
                                {/* <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant={"subtitle1"} component={"span"} className={classes.text}>Display veracity indicators on all verified content</Typography>
                        <FormControlLabel value="2" control={<Radio color="primary" className={classes.radioVaracity} />} label="" />
                    </Box> */}

                                {/* Option 4 */}
                                {/* <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant={"subtitle1"} component={"span"} className={classes.text}>Privacy rules: Screen out content and creators below a certain score</Typography>
                        <FormControlLabel value="4" control={<Radio color="primary" className={classes.radioVaracity} />} label="" />
                    </Box> */}
                            </RadioGroup>
                            {errors.veracityScore && (
                                <Typography color="error" variant="caption" style={{ maxWidth: 125, marginRight: 12 }}>
                                    {errors.veracityScore.message}
                                </Typography>
                            )}
                        </>
                    )}
                />
            </FormControl>
        </DialogWrapper>
    );
};

export default VeracityModal;

import React, { FC, ReactElement, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { Avatar, Button, Typography } from "@material-ui/core";
import { FormControl, FormGroup, InputLabel } from "@material-ui/core";
import { FilledSelect } from "../../../../components/FilledSelect/FilledSelect";
import TweetInput from "../../../../components/TweetInput/TweetInput";
import { ImageObj } from "../../../../components/AddTweetForm/AddTweetForm";
import { selectUserData } from "../../../../store/ducks/user/selectors";
import { uploadImage } from "../../../../util/upload-image-helper";
import UploadProfileImage from "../../../../components/UploadProfileImage/UploadProfileImage";
import { useEditProfileModalStyles } from "./EditProfileModalStyles";
import { DEFAULT_PROFILE_IMG } from "../../../../constants/url-constants";
import { updatedUserData } from "../../../../store/ducks/user/actionCreators";
import { useGlobalStyles } from "../../../../util/globalClasses";
import DialogTitleComponent from "../../../../components/DialogTitleComponent/DialogTitleComponent";

interface EditProfileModalProps {
    visible?: boolean;
    onClose: () => void;
}

export interface EditProfileFormProps {
    fullName: string;
    about: string;
    birthday: string;
    location: string;
    website: string;
    month: string;
    day: string;
    year: string;
}


const EditProfileFormSchema = yup.object().shape({
    fullName: yup.string().min(1, "Name can’t be blank").required("Name is required"),
    month: yup.string().required("Month is required"),
    day: yup.number().required("Day is required").positive().integer().min(1).max(31),
    year: yup.number().required("Year is required").positive().integer().min(1900).max(new Date().getFullYear()),
});

const EditProfileModal: FC<EditProfileModalProps> = ({ visible, onClose }): ReactElement | null => {
    const globalClasses = useGlobalStyles({});
    const classes = useEditProfileModalStyles();
    const dispatch = useDispatch();
    const userData = useSelector(selectUserData);
    const [avatar, setAvatar] = useState<ImageObj>();
    const [wallpaper, setWallpaper] = useState<ImageObj>();
    const [ageError, setAgeError] = useState<string | null>(null);  // New state for age validation error
    const dob = userData?.birthday ? userData.birthday.split(" ") : ["", "", ""];

    const { control, handleSubmit, setValue, watch, formState: { errors } } = useForm<EditProfileFormProps>({
        defaultValues: {
            fullName: userData?.fullName,
            about: userData?.about,
            location: userData?.location,
            website: userData?.website,
            month: dob[0],    // e.g., "Mar"
            day: dob[1]?.replace(",", ""),    // e.g., "11" (remove the comma)
            year: dob[2]      // e.g., "2010"
        },
        resolver: yupResolver(EditProfileFormSchema)
    });

    const month = watch('month');
    const day = watch('day');
    const year = watch('year');

    const isGreaterThan13YearsOld = (birthDate: Date): boolean => {
        const today = new Date();
    
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 1; // months are 0-indexed
        const currentDay = today.getDate();
    
        const birthYear = birthDate.getFullYear();
        const birthMonth = birthDate.getMonth() + 1; // months are 0-indexed
        const birthDay = birthDate.getDate();
    
        const yearDifference = currentYear - birthYear;
    
        // If the year difference is greater than 13, the age is definitely over 13
        if (yearDifference > 13) return true;
    
        // If the year difference is less than 13, it's definitely under 13
        if (yearDifference < 13) return false;
    
        // If the year difference is exactly 13, we check the months and days
        if (birthMonth < currentMonth) {
            return true; // Birth month has passed, so they are over 13
        } else if (birthMonth === currentMonth && birthDay < currentDay) {
            return true; // Birth month is same, but birth day has passed, so they are over 13
        }
    
        // Otherwise, they are exactly 13 years old and today is their birthday (or they're still younger)
        return false;
    };
    
    
    
    useEffect(() => {
        if (month && day && year) {
            const birthDate = new Date(`${month} ${day}, ${year}`);
            if (!isGreaterThan13YearsOld(birthDate)) {
                setAgeError('You must be older than 13 years.');
            } else {
                setAgeError(null);  // Clear the error if the age is valid (strictly greater than 13)
            }
            setValue('birthday', `${month} ${day}, ${year}`);
        }
    }, [month, day, year, setValue]);
    
    

    const onSubmit = async (data: EditProfileFormProps): Promise<void> => {
        let avatarResponse: string | undefined = undefined;
        let wallpaperResponse: string | undefined = undefined;
        if (avatar) {
            avatarResponse = await uploadImage(avatar.file);
        }
        if (wallpaper) {
            wallpaperResponse = await uploadImage(wallpaper.file);
        }
        
        dispatch(updatedUserData({ ...data, avatar: avatarResponse!, wallpaper: wallpaperResponse! }));
        onClose();
    };

    if (!visible) {
        return null;
    }

    return (
        <Dialog open={visible} onClose={onClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitleComponent title={"Edit Profile"} onClose={onClose}>
                    <Button type="submit" variant="contained" color="primary" disabled={!!ageError}>
                        Save
                    </Button>
                </DialogTitleComponent>
                <DialogContent className={globalClasses.dialogContent}>
                    <div>
                        <div className={classes.wallpaperWrapper}>
                            <img
                                className={classes.wallpaperImg}
                                key={wallpaper?.src}
                                src={(userData?.wallpaper && !wallpaper?.src) ?
                                    userData?.wallpaper : wallpaper?.src}
                            />
                            <div className={classes.wallpaperEditImg}>
                                <UploadProfileImage name={"wallpaper"} image={wallpaper} onChangeImage={setWallpaper} />
                            </div>
                        </div>
                        <div className={classes.avatarWrapper}>
                            <UploadProfileImage name={"avatar"} image={avatar} onChangeImage={setAvatar} />
                            <Avatar
                                key={avatar?.src}
                                src={(userData?.avatar && !avatar?.src) ? userData?.avatar : avatar?.src}
                            >
                                <img alt="default-img" src={DEFAULT_PROFILE_IMG} />
                            </Avatar>
                        </div>
                        <FormControl className={classes.inputWrapper} variant="outlined">
                            <FormGroup aria-label="position">
                                <Controller
                                    name="fullName"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <TweetInput
                                            name="fullName"
                                            helperText={errors.fullName?.message}
                                            error={!!errors.fullName}
                                            label={"Name"}
                                            maxTextLength={50}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                                <Controller
                                    name="about"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <TweetInput
                                            name="about"
                                            label={"Bio"}
                                            maxTextLength={160}
                                            onChange={onChange}
                                            value={value}
                                            rows={3}
                                            multiline
                                        />
                                    )}
                                />
                                <Controller
                                    name="location"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <TweetInput
                                            name="location"
                                            label={"Location"}
                                            maxTextLength={30}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                                <Controller
                                    name="website"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <TweetInput
                                            name="website"
                                            label={"Website"}
                                            maxTextLength={100}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                                {/* Date of Birth Label */}
                                <div className={classes.container}>
                                  <Typography variant="h6" style={{marginTop: "12px"}}>
                                      Date of Birth
                                  </Typography>

                                  {/* Month, Day, and Year in a single row */}
                                  <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: '1rem' }}>
                                      {/* Month */}
                                      <Controller
                                          name="month"
                                          control={control}
                                          render={({ field: { onChange, value } }) => (
                                              <FormControl variant="filled" style={{ minWidth: '120px', flexGrow: 1 }}>
                                                  <InputLabel htmlFor="select-month">Month</InputLabel>
                                                  <FilledSelect
                                                      value={value}
                                                      onChange={onChange}
                                                      variant="filled"
                                                      labelId="select-month"
                                                      id="select-month"
                                                      native
                                                  >
                                                      <option value={"Jan"}>January</option>
                                                      <option value={"Feb"}>February</option>
                                                      <option value={"Mar"}>March</option>
                                                      <option value={"Apr"}>April</option>
                                                      <option value={"May"}>May</option>
                                                      <option value={"Jun"}>June</option>
                                                      <option value={"Jul"}>July</option>
                                                      <option value={"Aug"}>August</option>
                                                      <option value={"Sep"}>September</option>
                                                      <option value={"Oct"}>October</option>
                                                      <option value={"Nov"}>November</option>
                                                      <option value={"Dec"}>December</option>
                                                  </FilledSelect>
                                                  {errors.month && <Typography color="error">{errors.month.message}</Typography>}
                                              </FormControl>
                                          )}
                                      />

                                      {/* Day */}
                                      <Controller
                                          name="day"
                                          control={control}
                                          render={({ field: { onChange, value } }) => (
                                              <FormControl variant="filled" style={{ minWidth: '80px', flexGrow: 1 }}>
                                                  <InputLabel htmlFor="select-day">Day</InputLabel>
                                                  <FilledSelect
                                                      value={value}
                                                      onChange={onChange}
                                                      variant="filled"
                                                      labelId="select-day"
                                                      id="select-day"
                                                      native
                                                  >
                                                      {[...Array(31)].map((_, i) => (
                                                          <option key={i + 1} value={i + 1}>
                                                              {i + 1}
                                                          </option>
                                                      ))}
                                                  </FilledSelect>
                                                  {errors.day && <Typography color="error">{errors.day.message}</Typography>}
                                              </FormControl>
                                          )}
                                      />

                                      {/* Year */}
                                      <Controller
                                          name="year"
                                          control={control}
                                          render={({ field: { onChange, value } }) => (
                                              <FormControl variant="filled" style={{ minWidth: '120px', flexGrow: 1 }}>
                                                  <InputLabel htmlFor="select-year">Year</InputLabel>
                                                  <FilledSelect
                                                      value={value}
                                                      onChange={onChange}
                                                      variant="filled"
                                                      labelId="select-year"
                                                      id="select-year"
                                                      native
                                                  >
                                                      {[...Array(121)].map((_, i) => (
                                                          <option key={new Date().getFullYear() - i} value={new Date().getFullYear() - i}>
                                                              {new Date().getFullYear() - i}
                                                          </option>
                                                      ))}
                                                  </FilledSelect>
                                                  {errors.year && <Typography color="error">{errors.year.message}</Typography>}
                                              </FormControl>
                                          )}
                                      />
                                  </div>

                                  {/* Display age validation error message */}
                                  {ageError && <Typography color="error">{ageError}</Typography>}
                                  {errors.birthday && <Typography color="error">{errors.birthday.message}</Typography>}
                                </div>
                            </FormGroup>
                        </FormControl>
                    </div>
                </DialogContent>
            </form>
        </Dialog>
    );
};

export default EditProfileModal;

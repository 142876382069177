import { makeStyles } from "@material-ui/core";

export const useHomeStyles = makeStyles((theme) => ({
    container: {
        position: 'relative'
    },
    header: {
        position: 'sticky',
        top: 0,
        width: 'unset',
        justifyContent: "space-between",
        "& .MuiTypography-h5": {
            marginLeft: 16,
            display: "inline-block",
            verticalAlign: "middle"
        }
    },
    headerIcon: {
        paddingRight: 10
    },
    addForm: {
        padding: "15px"
    },
    divider: {
        height: 12,
        backgroundColor: theme.palette.divider
    }
}));

import produce, { Draft } from 'immer';
import { CommonActions, CommonActionsType } from './contracts/actionTypes';
import { CommonState, commonInitstatus } from './contracts/state';

const initState: CommonState = {...commonInitstatus};

export const commonReducer = produce((draft: Draft<CommonState>, action: CommonActions) => {
  switch (action.type) {
    case CommonActionsType.SET_COMMON_STATUS:
      draft.isLoading = action.payload.isLoading;
      draft.isSuccess = action.payload.isSuccess;
      draft.isFail = action.payload.isFail;
      break;
    default:
      break;
  }
 }, initState);
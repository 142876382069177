import { makeStyles } from "@material-ui/core";

export const useSideSearchStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: 4,
    position: "sticky",
    top: 0,
    zIndex: 10,
    height: 53,
    marginLeft: '12px',
    "& .MuiInputAdornment-positionEnd": {
      marginRight: 12
    },
    "& .MuiFormControl-root": {
      paddingLeft: '0px',
      '& .MuiInputBase-root': {
        position: 'relative',
        '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
          display: 'inline-block',
          height: 'unset',
          position: 'absolute',
          right: 0,
          top: "50%",
          transform: "translateY(-50%)"
        }
      }
    },
  },
  dropdown: {
    padding: 0,
    position: "absolute",
    maxWidth: '100%',
    top: 50,
    right: 0,
    left: 0,
    zIndex: 2,
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px",
    '& .MuiList-root': {
      maxWidth: '100%',
      height: 320,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.background.default,
      },
    },
    "& .MuiListItem-root": {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.palette.secondary.main
      }
    }
  }
}));
import React, { FC, memo, ReactElement } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "@material-ui/core";

import { PROFILE } from "../../../constants/path-constants";
import { useGlobalStyles } from "../../../util/globalClasses";
import { HoverItemDetail, useHoverItem } from "../../../hook/useHoverItem";
import { fetchUserDetail } from "../../../store/ducks/userDetail/actionCreators";
import PopperUserWindow from '../../PopperUserWindow/PopperUserWindow';

interface TweetAvatarProps {
    src?: string;
    userId?: number;
}

const TweetAvatar: FC<TweetAvatarProps> = memo(({ src, userId }): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const { visiblePopperWindow, handleHoverPopper, handleLeavePopper } = useHoverItem(fetchUserDetail);

    return (
      <span
        onMouseEnter={() => handleHoverPopper({ userId: userId! } as HoverItemDetail)}
        onMouseLeave={handleLeavePopper}
      >
        <Link to={`${PROFILE}/${userId}`}>
          <Avatar className={globalClasses.avatar} src={src} alt={`avatar ${userId}`} />
         </Link>
        <PopperUserWindow visible={visiblePopperWindow} isTweetComponent />
      </span>
    );
});

export default TweetAvatar;

import React, { FC, memo, ReactElement } from "react";

import { ReplyIcon } from "../../../icons";
import ActionIconButton from "../../../components/ActionIconButton/ActionIconButton";
import { useReplyIconButtonStyles } from "./ReplyIconButtonStyles";

interface ReplyIconButtonProps {
  repliesCount?: number;
}

const ReplyIconButton: FC<ReplyIconButtonProps> = memo(({ repliesCount }
): ReactElement => {
    const classes = useReplyIconButtonStyles();

    return (
      <div className={classes.infoIcon}>
        <ActionIconButton actionText={"Reply"} icon={ReplyIcon} />
        {(repliesCount !== 0) && (
          <span id={"repliesCount"} className={classes.repliesCount}>
            {repliesCount}
          </span>
        )}
      </div>
    );
});

export default ReplyIconButton;

import axios, { AxiosResponse } from "axios";
import {
    LaunchSoonPayload
} from "../../../store/ducks/launchSoon/contracts/state";
import { API_V1_LAUNCH_SOON } from '../../../constants/endpoint-constants';

export const LaunchApi = {
    async processPreRegistration(request: LaunchSoonPayload): Promise<AxiosResponse<any>> {
        return await axios.post<any[]>(API_V1_LAUNCH_SOON, request);
    },
}
import { makeStyles, Theme } from "@material-ui/core";
import { UserItemSize } from "./UsersItem";

interface UsersItemStylesProps {
    size?: UserItemSize;
}

export const useUsersItemStyles = makeStyles<Theme, UsersItemStylesProps>((theme) => ({
    container: {
        cursor: "pointer",
        borderBottom: `1px solid ${theme.palette.divider}`,
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark
        },
        "& a": {
            color: "inherit",
            textDecoration: "none"
        }
    },
    userInfo: {
        display: 'flex',
        gap: '12px',
        marginLeft: 0,
        "& .MuiTypography-h6, .MuiTypography-subtitle1": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
        }
    },
    userInfoDetails: {
        width: props => (props.size === UserItemSize.SMALL) ? 120 : 360,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    buttonWrapper: {
        marginLeft: 'auto'
        // flex: 1,
        // "& .MuiButton-root": {
        //     float: "right"
        // }
    }
}));

import { makeStyles } from '@material-ui/core';

export const useMentionsListStyle = makeStyles((theme) => ({
    mentionList: {
        position: "relative"
    },
    popover: {
        "& .MuiPaper-root": {
            maxWidth: 325,
            maxHeight: 290,
            boxShadow: "rgb(101 119 134 / 20%) 0px 0px 15px, rgb(101 119 134 / 15%) 0px 0px 3px 1px",
            borderRadius: 16,
            marginTop: "2%",
            '&::-webkit-scrollbar': {
                width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: '3px',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.background.default,
            },
        }
    }
}))

import React, { ChangeEvent, FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as MuiLink, Snackbar, Typography } from "@material-ui/core";

import { useEmailVerificationModalStyles } from "./EmailVerificationModalStyles";
import { RegistrationInputField } from "../RegistrationInput/RegistrationInputField";
import DialogWrapper from "../DialogWrapper/DialogWrapper";
import {
    selectErrorMessage,
    selectRegistrationInfo,
    selectRegistrationStep4
} from "../../../store/ducks/authentication/selector";
import { fetchCheckRegistrationCode, fetchSendRegistrationCode } from "../../../store/ducks/authentication/actionCreators";
import { AuthenticationApi } from "../../../services/api/user-service/authenticationApi";
import { setOpenSnackBar } from "../../../store/ducks/actionSnackbar/actionCreators";

const EmailVerificationModal: FC = (): ReactElement => {
    const classes = useEmailVerificationModalStyles();
    const dispatch = useDispatch();
    const registrationInfo = useSelector(selectRegistrationInfo);
    const registrationStep4 = useSelector(selectRegistrationStep4);
    const errorMessage = useSelector(selectErrorMessage);
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [isCooldown, setIsCooldown] = useState(false); // To track cooldown state
    const [countdown, setCountdown] = useState(60); // Countdown for 1 minute (60 seconds)

    const checkEmailVerificationCode = (): void => {
        dispatch(fetchCheckRegistrationCode(verificationCode));
    };

    const onChangeVerificationCode = (event: ChangeEvent<HTMLInputElement>): void => {
        setVerificationCode(event.target.value);
    };

    const handleResendCode = async (): Promise<void> => {
        try {
            // Dispatch action to resend code
            dispatch(fetchSendRegistrationCode(registrationInfo));
            dispatch(setOpenSnackBar("Verification code sent successfully"));
            setVerificationCode(""); // Clear the input field after resending

            // Start the cooldown for 1 minute
            setIsCooldown(true);
            setCountdown(60); // Reset countdown to 60 seconds
        } catch (error) {
            console.error("Failed to resend verification code:", error);
        }
    };

    // Start countdown when modal is opened (registrationStep4 becomes true)
    useEffect(() => {
        if (registrationStep4) {
            setIsCooldown(true); // Start cooldown immediately when modal opens
            setCountdown(60); // Reset countdown to 60 seconds
        }
    }, [registrationStep4]);
    
    // Countdown effect when cooldown is active
    useEffect(() => {
        if (isCooldown && countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1); // Decrease countdown every second
            }, 1000);
            return () => clearTimeout(timer); // Clear timer on component unmount or change
        } else if (countdown === 0) {
            setIsCooldown(false); // Reset cooldown when countdown finishes
        }
    }, [isCooldown, countdown]);

    return (
        <DialogWrapper
            isOpen={registrationStep4}
            onClick={checkEmailVerificationCode}
            disabledButton={!verificationCode}
        >
            <Typography component={"div"} className={classes.title}>
                Step 4 of 6
            </Typography>
            <Typography variant={"h3"} component={"div"}>
                We sent you a code
            </Typography>
            <Typography variant={"subtitle1"} component={"div"}>
                {`Enter it below to verify ${registrationInfo.email}.`}
            </Typography>
            <div style={{ marginTop: 10 }}>
                <RegistrationInputField
                    label="Verification code"
                    variant="filled"
                    helperText={errorMessage}
                    error={errorMessage !== null}
                    value={verificationCode}
                    onChange={onChangeVerificationCode}
                    fullWidth
                    autoFocus
                />
            </div>
            
            <MuiLink
              variant="subtitle2"
              target="_blank"
              rel="noopener"
              className={classes.emailLinkWrapper}
              onClick={handleResendCode}
              style={{ 
                cursor: isCooldown ? 'not-allowed' : 'pointer', 
                pointerEvents: isCooldown ? 'none' : 'auto',
                color: isCooldown ? 'gray' : 'rgb(29, 155, 240)',
                width: "max-content"
              }}
            >
              Didn't receive email? {isCooldown && `(${countdown}s)`}
            </MuiLink>
            <MuiLink variant="subtitle2" href="#" className={classes.emailLinkWrapper}>
            </MuiLink>
        </DialogWrapper>
    );
};

export default EmailVerificationModal;
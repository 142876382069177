import React, { FC, ReactElement, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { FormControl, InputLabel, Typography } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useRegistrationModalStyles } from "./RegistrationModalStyles";
import RegistrationInput from "../RegistrationInput/RegistrationInput";
import { FilledSelect } from "../../../components/FilledSelect/FilledSelect";
import DialogWrapper from "../DialogWrapper/DialogWrapper";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { selectErrorMessage, selectIsUsernameAvailable, selectRegistrationStep1 } from "../../../store/ducks/authentication/selector";
import { checkUsernameAvailability, fetchRegistration } from "../../../store/ducks/authentication/actionCreators";
import { userNameValidator } from '../../../util/helper';

export interface RegistrationFormProps {
    fullname: string;
    username: string;
    email: string;
    month: string;
    day: number;
    year: number;
}

const RegistrationFormSchema = yup.object().shape({
    fullname: yup.string().min(1, "What is your name?").required(),
    // username: yup.string().min(1, "What is your Username?").required(),
    username: yup
        .string()
        .min(4, "Username must be at least 4 characters long")
        .max(50, "Your username must be shorter than 50 characters")
        .matches(/^[a-zA-Z0-9_]+$/, "Username can only contain letters, numbers, and underscores")
        .test("no-at-symbol", "Username cannot be '@'", (value) => value !== "@")
        .required("What is your Username?"),
    email: yup
        .string()
        .required("Please enter a valid email.")
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, "Invalid email"),
    month: yup.string().required("Month is required."),
    day: yup
        .number()
        .min(1, "Day is required.")
        .max(31, "Invalid day.")
        .required("Day is required."),
    year: yup
        .number()
        .min(1900, "Year is required.")
        .max(new Date().getFullYear(), "Invalid year.")
        .required("Year is required."),
});

const RegistrationModal: FC = (): ReactElement => {
    const classes = useRegistrationModalStyles();
    const dispatch = useDispatch();
    const registrationStep1 = useSelector(selectRegistrationStep1);
    const { control, handleSubmit, setError, clearErrors, formState: { errors } } = useForm<RegistrationFormProps>({
        resolver: yupResolver(RegistrationFormSchema),
    });
    const isUsernameAvailable = useSelector(selectIsUsernameAvailable);
    const errorMessage = useSelector(selectErrorMessage);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const isOver13YearsOld = (month: string, day: number, year: number): boolean => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // months are 0-indexed
        const currentDay = currentDate.getDate();

        const yearDifference = currentYear - year;

        if (yearDifference > 13) return true;
        if (yearDifference < 13) return false;

        // Check month and day if year difference is exactly 13
        const monthIndex = new Date(`${month} 1`).getMonth() + 1; // Convert month string to index

        if (monthIndex < currentMonth) return true;
        if (monthIndex === currentMonth && day <= currentDay) return true;

        return false;
    };

    const onSubmit = async (data: RegistrationFormProps): Promise<void> => {
        const { month, day, year } = data;
        if (!isUsernameAvailable || errorMessage) {
            return;
        }
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }
        // Trigger the reCAPTCHA v3 challenge
        const recaptchaToken = await executeRecaptcha();

        // Clear previous errors
        clearErrors();

        if (!isOver13YearsOld(month, day, year)) {
            // Set error for the month field only
            setError("day", { type: "manual", message: "" });
            setError("month", { type: "manual", message: "You must be at least 13 years old." });
            setError("year", { type: "manual", message: "" });
            return;
        }

        // Convert the email to lowercase before saving
        const formattedData = {
            ...data,
            email: data.email.toLowerCase(),
        };

        const birthday = `${month} ${day}, ${year}`;
        dispatch(fetchRegistration({ registrationData: { ...formattedData, birthday, recaptchaToken }, setError }));
    };

    const showDays = (): ReactNode[] => {
        let days = [];
        for (let i = 1; i <= 31; i++) {
            days.push(<option key={i} value={i}>{i}</option>);
        }
        return days;
    };

    const showYears = (): ReactNode[] => {
        let years = [];
        for (let i = new Date().getFullYear(); i >= 1901; i--) {
            years.push(<option key={i} value={i}>{i}</option>);
        }
        return years;
    };

    useEffect(() => {
        if (isUsernameAvailable === false) {
            setError("username", { type: "manual", message: "Username is already taken" });
        } else if (errorMessage) {
            setError("username", { type: "manual", message: errorMessage });
        } else {
            clearErrors("username");
        }
    }, [isUsernameAvailable, errorMessage, setError, clearErrors]);

    const handleUsernameChange = (value: string) => {
        const validationError = userNameValidator(value);
        if (validationError) {
            setError("username", { type: "manual", message: validationError });
            return;
        }
        clearErrors();
        dispatch(checkUsernameAvailability(value));
    };

    return (
        <DialogWrapper isOpen={registrationStep1} onClick={handleSubmit(onSubmit)}>
            <Typography variant={"h3"} component={"div"} className={classes.title}>
                Create your account
            </Typography>
            <FormControl className={classes.inputWrapper} variant="outlined">
                <Controller
                    name="fullname"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <RegistrationInput
                            name="name"
                            helperText={errors.fullname?.message}
                            error={!!errors.fullname}
                            label={"Name"}
                            maxTextLength={50}
                            onChange={onChange}
                            value={value}
                            autoFocus={true}
                        />
                    )}
                />

                <Controller
                    name="username"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <RegistrationInput
                            name="username"
                            helperText={errors.username?.message || (isUsernameAvailable === false ? "Username is already taken" : "")}
                            error={!!errors.username || isUsernameAvailable === false}
                            label={"Username"}
                            maxTextLength={15}
                            onChange={(e) => {
                                handleUsernameChange(e.target.value);
                                onChange(e);
                            }}
                            value={value}
                        />
                    )}
                />

                <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                        <RegistrationInput
                            name="email"
                            helperText={errors.email?.message}
                            error={!!errors.email}
                            label={"Email"}
                            maxTextLength={50}
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </FormControl>
            <div className={classes.footer}>
                <Typography variant={"h6"} component={"div"}>
                    Date of birth
                </Typography>
                <Typography variant={"subtitle1"} component={"div"}>
                    This will not be shown publicly. Confirm your own age, even if this account is for a
                    business, a pet, or something else.
                </Typography>
                <div className={classes.formControl}>
                    <FormControl variant="filled">
                        <Controller
                            name="month"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <InputLabel htmlFor="select-month">Month</InputLabel>
                                    <FilledSelect
                                        name="month"
                                        variant="filled"
                                        style={{ marginRight: 12 }}
                                        labelId="select-month"
                                        id="select-month"
                                        native
                                        value={value}
                                        onChange={onChange}
                                        label="Month"
                                        error={!!errors.month}
                                    >
                                        <option aria-label="None" style={{ display: "none" }} />
                                        <option value={"Jan"}>January</option>
                                        <option value={"Feb"}>February</option>
                                        <option value={"Mar"}>March</option>
                                        <option value={"Apr"}>April</option>
                                        <option value={"May"}>May</option>
                                        <option value={"Jun"}>June</option>
                                        <option value={"Jul"}>July</option>
                                        <option value={"Aug"}>August</option>
                                        <option value={"Sep"}>September</option>
                                        <option value={"Oct"}>October</option>
                                        <option value={"Nov"}>November</option>
                                        <option value={"Dec"}>December</option>
                                    </FilledSelect>
                                    {errors.month && (
                                        <Typography color="error" variant="caption">
                                            {errors.month.message}
                                        </Typography>
                                    )}
                                </>
                            )}
                        />
                    </FormControl>
                    <FormControl variant="filled">
                        <Controller
                            name="day"
                            control={control}
                            defaultValue={0}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <InputLabel htmlFor="select-day">Day</InputLabel>
                                    <FilledSelect
                                        name="day"
                                        variant="filled"
                                        style={{ marginRight: 12 }}
                                        labelId="select-day"
                                        id="select-day"
                                        native
                                        value={value}
                                        onChange={onChange}
                                        label="Day"
                                        error={!!errors.day}
                                    >
                                        <option aria-label="None" style={{ display: "none" }} />
                                        {showDays()}
                                    </FilledSelect>
                                    {errors.day && (
                                        <Typography color="error" variant="caption" style={{ maxWidth: 125, marginRight: 12 }}>
                                            {errors.day.message}
                                        </Typography>
                                    )}
                                </>
                            )}
                        />
                    </FormControl>
                    <FormControl variant="filled">
                        <Controller
                            name="year"
                            control={control}
                            defaultValue={0}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <InputLabel htmlFor="select-year">Year</InputLabel>
                                    <FilledSelect
                                        name="year"
                                        variant="filled"
                                        // style={{ maxWidth: 125 }}
                                        labelId="select-year"
                                        id="select-year"
                                        native
                                        value={value}
                                        onChange={onChange}
                                        label="Year"
                                        error={!!errors.year}
                                    >
                                        <option aria-label="None" style={{ display: "none" }} />
                                        {showYears()}
                                    </FilledSelect>
                                    {errors.year && (
                                        <Typography color="error" variant="caption" style={{ maxWidth: 125, marginRight: 12 }}>
                                            {errors.year.message}
                                        </Typography>
                                    )}
                                </>
                            )}
                        />
                    </FormControl>
                </div>
            </div>
        </DialogWrapper>
    );
};

const RegistrationWrapper: FC = (): ReactElement => (
    <GoogleReCaptchaProvider reCaptchaKey="6LeGej4qAAAAAABKGxLw5FY4DGAaquNMtfpxcQTy">
        <RegistrationModal />
    </GoogleReCaptchaProvider>
);

export default RegistrationWrapper;

import { Action } from "redux";
import { CommonStatus } from '../../../../types/common';

export enum CommonActionsType {
    SET_COMMON_STATUS = "common/SET_STATUS",
}

export interface SetCommonStatusLoadingStateActionInterface extends Action<CommonActionsType> {
    type: CommonActionsType.SET_COMMON_STATUS;
    payload: CommonStatus;
}

export type CommonActions =
SetCommonStatusLoadingStateActionInterface
export interface CommonState {
  isLoading: boolean;
  isSuccess: boolean;
  isFail: boolean;
}

export const commonInitstatus = {
  isLoading: false,
  isSuccess: false,
  isFail: false,
}
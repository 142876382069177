import { RootState } from "../../store";
import { TweetSentState, TweetsState } from "./contracts/state";
import { LoadingStatus } from "../../../types/common";

export const selectTweetsState = (state: RootState): TweetsState => state.tweets;
export const selectLoadingState = (state: RootState): LoadingStatus => selectTweetsState(state).loadingState;
export const selectIsTweetsLoading = (state: RootState): boolean => selectLoadingState(state) === LoadingStatus.LOADING;
export const selectIsTweetsLoaded = (state: RootState): boolean => selectLoadingState(state) === LoadingStatus.LOADED;

export const selectTweetsItems = (state: RootState) => selectTweetsState(state).items;
export const selectTweetsItemsSize = (state: RootState) => selectTweetsState(state).items.length;
export const selectPagesCount = (state: RootState) => selectTweetsState(state).pagesCount;
export const selectDeleteTweetId = (state: RootState) => selectTweetsState(state).deletedTweetsId;

export const selectIsTweetSentStateIdel = (state: RootState) => selectTweetsState(state).tweetSentState === TweetSentState.IDEL;
export const selectIsTweetSentStateSent = (state: RootState) => selectTweetsState(state).tweetSentState === TweetSentState.SENT;
export const selectIsTweetSentStateError = (state: RootState) => selectTweetsState(state).tweetSentState === TweetSentState.ERROR;

export const selectMentionItems = (state: RootState) => selectTweetsState(state).mentionItems;
export const selectExploreItems = (state: RootState) => selectTweetsState(state).exploreItems;
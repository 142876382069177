import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { resetUsersMentionsState } from "../../../store/ducks/usersMentions/actionCreators";
import { useMentionsListStyle } from "./MentionsListStyle";
import { selectUserDataId } from "../../../store/ducks/user/selectors";
import { usePopup } from "../../../hook/usePopup";
import MentionModalUser from "./MentionModalUser/MentionModalUser";
import { usePopover } from "../../../context/PopoverContext";
interface IProps {
    usersMentionsList: any[];
    handleSelect: (username: string) => void;
}

const MentionsList = ({ usersMentionsList, handleSelect }: IProps) => {
    const classes = useMentionsListStyle();
    const dispatch = useDispatch();
    const myProfileId = useSelector(selectUserDataId);
    const ref = useRef(null);
    const { popoverId, handleCurrentRef, openPopover, handleClosePopup } = usePopup();
    const { anchorEl, setAnchorEl } = usePopover();

    useEffect(() => {
        // Ensure only one popover anchor is handled
        if (usersMentionsList.length) {
            handleCurrentRef(anchorEl);
        } else if (!usersMentionsList.length) {
            handleClosePopup();
        }
    }, [usersMentionsList]); // Include anchor in the dependency array

    const handleClose = () => {
        handleClosePopup();
        setAnchorEl(null);
        dispatch(resetUsersMentionsState());
    };

    return (
        <div className={classes.mentionList} ref={ref}>
            <Popover
                id={popoverId}
                className={classes.popover}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <div>
                    <List component="nav">
                        {usersMentionsList.map((user: any) => (
                            <ListItem
                                key={user.id}
                                disabled={user.isMutedDirectMessages || user.id === myProfileId}
                                onClick={() => {
                                    handleSelect(user.username);
                                    handleClosePopup();
                                }}
                                button
                            >
                                <MentionModalUser user={user} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Popover>
        </div>
    );
};

export default MentionsList;

import { makeStyles } from "@material-ui/core";

export const useChangeYourPasswordStyles = makeStyles(() => ({
    buttonWrapper: {
        float: "right"
    },
    customLink: {
      color: "rgb(29, 155, 240)",
      cursor: "pointer",
    }
}));

import React, { ChangeEvent, FC, FormEvent, ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Link as MuiLink, Typography } from "@material-ui/core";

import { ForgotPasswordTextField } from "../ForgotPasswordTextField/ForgotPasswordTextField";
import { useCheckEmailCodeStyles } from "./CheckEmailCodeStyles";
import { ACCOUNT_FORGOT_RESET_PASSWORD } from "../../../constants/path-constants";
import { AuthenticationApi } from "../../../services/api/user-service/authenticationApi";

const CheckEmailCode: FC = (): ReactElement => {
    const classes = useCheckEmailCodeStyles();
    const history = useHistory();
    const location = useLocation<{ email: string, from?: string }>();
    const [resetCode, setResetCode] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [timer, setTimer] = useState<number>(60);

    useEffect(() => {
      let countdown: string | number | NodeJS.Timeout | undefined;
      if (isDisabled && timer > 0) {
          countdown = setTimeout(() => {
              setTimer(timer - 1);
          }, 1000);
      } else if (timer === 0) {
          setIsDisabled(false); // Re-enable the link after 60 seconds
      }

      // Clean up the timeout when the component unmounts or when the timer updates
      return () => clearTimeout(countdown);
    }, [timer, isDisabled]);

    const verifyResetCode = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (!resetCode) {
            setError(true);
        } else {
            AuthenticationApi.getUserByPasswordResetCode(resetCode)
                .then((response) => {
                    history.push({ pathname: ACCOUNT_FORGOT_RESET_PASSWORD, state: { user: response.data, from: location.state?.from } });
                })
                .catch(() => setError(true));
        }
    };

    const handleChangeResetCode = (event: ChangeEvent<HTMLInputElement>): void => {
        setResetCode(event.target.value);
    };

    const handleResendCodeClick = (event: any): void => {
      event.preventDefault();
      setIsDisabled(true);
      setTimer(60);
      AuthenticationApi.resendCode({ email: location.state.email })
      .then(() => {})
      .catch((error) => {
          console.log(error);
      });
    };

    return (
      <>
        <Typography variant={"h3"} component={"div"}>
            Check your email
        </Typography>
        <Typography variant={"body1"} component={"div"} className={classes.text}>
            You'll receive a code to verify here so you can reset your account password.
        </Typography>
        <form onSubmit={verifyResetCode} style={{ display: "flex", flexDirection: "column" }}>
            <ForgotPasswordTextField
                error={error}
                placeholder="Enter your code"
                variant="outlined"
                onChange={handleChangeResetCode}
                value={resetCode}
                autoFocus
            />
            {error && (
                <Typography component={"div"} className={classes.errorMessage}>
                    Incorrect code. Please try again.
                </Typography>
            )}
            <MuiLink
              variant="subtitle2"
              target="_blank"
              rel="noopener"
              onClick={handleResendCodeClick}
              style={{ 
                marginTop: "6px",
                marginLeft: "10px",
                cursor: isDisabled ? 'not-allowed' : 'pointer', 
                pointerEvents: isDisabled ? 'none' : 'auto',
                color: isDisabled ? 'gray' : 'rgb(29, 155, 240)',
                width: "max-content"
              }}
            >
              Didn’t receive your code? {isDisabled && `(${timer}s)`}
            </MuiLink>
            <Button
                className={classes.button}
                type="submit"
                variant="contained"
                color="primary"
                size="small"
            >
                Verify
            </Button>
        </form>
        <div>
          <Typography variant={"body1"} component={"div"} className={classes.footerText}>
              If you don't see the email, check other places it might be, like your junk, spam, social,
              or other folders.
          </Typography>
        </div>
      </>
    );
};

export default CheckEmailCode;

import React, { ReactNode } from "react";
import reactStringReplace from "react-string-replace";
import { Emoji } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";

import { TaggedUserResponse, UserResponse } from "../types/user";
import MentionLink from '../components/AddTweetForm/MentionsList/MentionLink';
import HashTagLink from '../components/HashTagLink';

export const capitalize = (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getUsersInImage = (users: (UserResponse | TaggedUserResponse)[]): string => {
    if (!users || users.length === 0) return "Tag people";

    const firstUser = users[0]?.fullName || "Unknown User";
    if (users.length === 1) {
        return firstUser;
    }

    const secondUser = users[1]?.fullName || "Unknown User";
    if (users.length === 2) {
        return `${firstUser} and ${secondUser}`;
    }

    return `${firstUser} and ${users.length - 1} others`;
};

export const textFormatter = (text: string): ReactNode => {
    let replacedText: ReactNode[] = [];
    let index: number = 1;

    // Convert Iterable<ReactNode> to ReactNode[]
    const addNodesToArray = (iterable: Iterable<ReactNode>) => {
        return Array.from(iterable);
    };

    replacedText = addNodesToArray(
        reactStringReplace(text, /(\n)/g, (match) => (
            <React.Fragment key={index++}>{match}<br /></React.Fragment>
        ))
    );

    replacedText = addNodesToArray(
        reactStringReplace(replacedText, /(#\w+)\b/ig, (match) => (
            <HashTagLink match={match} key={index++} />
        ))
    );

    replacedText = addNodesToArray(
        reactStringReplace(replacedText, /(@\w+)\b/ig, (match) => (
           <MentionLink match={match} key={index++} />
        ))
    );

    replacedText = addNodesToArray(
        reactStringReplace(replacedText, /(https?:\/\/[^\s]+)/g, (match) => (
            <a key={index++} href={match} id="link" target="_blank" rel="noopener noreferrer">{match}</a>
        ))
    );

    replacedText = addNodesToArray(
        reactStringReplace(replacedText, /:(.+?):/g, (match) => (
            <Emoji native={false} key={index++} emoji={match} set={"twitter"} size={20} />
        ))
    );

    return replacedText;
};

export const decodeHTMLEntities = (text: string): string | null => {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(`<!doctype html><body>${text}`, 'text/html').body.textContent;
    return decodedString;
}
import { FC, ReactElement, memo } from 'react';
import { UserResponse } from '../../../../types/user';
import { useMentionModalUserStyle } from './MentionModalUserStyle';
import { DEFAULT_PROFILE_IMG } from '../../../../constants/url-constants';
import { Avatar, Typography } from '@material-ui/core';
import LockIcon from '../../../LockIcon/LockIcon';

interface MessagesModalUser {
    user: UserResponse
}
const MentionModalUser: FC<MessagesModalUser> = memo(({ user }): ReactElement => {
    const classes = useMentionModalUserStyle();

    return (
        <div className={classes.container}>
            <Avatar className={classes.listAvatar} src={user?.avatar ?? DEFAULT_PROFILE_IMG} />
            <div style={{ flex: 1 }}>
                <div className={classes.header}>
                    <div className={classes.headerInfo}>
                        <div>
                            <Typography variant={"h6"} component={"span"}>
                                {user?.fullName}
                            </Typography>
                            {user?.isPrivateProfile && <LockIcon />}
                        </div>
                        <Typography variant={"subtitle1"} component={"div"}>
                            @{user?.username}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default MentionModalUser;
import { makeStyles } from "@material-ui/core";

export const useTweetImageStyles = makeStyles((theme) => ({
    image: {
        position: "relative",
        "& img": {
            objectFit: "cover",
            marginTop: 10,
            width: '100%',
            height: 'auto',
            borderRadius: 20,
            borderColor: theme.palette.info.light
        },
        "& .small": {
            width: 260
        }
    },
    imageLink: {
        display: 'block'
    }
}));

import { LoadingStatus } from '../../../types/common';
import { RootState } from '../../store';
import { NewsState } from './contracts/state';

export const selectNews = (state: RootState): NewsState => state.news;
export const selectNewsList = (state: RootState) => selectNews(state).news;
export const selectNewsPagesCount = (state: RootState): number => selectNews(state).pagesCount;

export const selectLoadingState = (state: RootState): LoadingStatus => selectNews(state).loadingState;
export const selectIsNewsLoading = (state: RootState): boolean => selectLoadingState(state) === LoadingStatus.LOADING;
export const selectIsNewsLoaded = (state: RootState): boolean => selectLoadingState(state) === LoadingStatus.LOADED;
import React, { ChangeEvent, FC, FormEvent, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Typography, IconButton } from "@material-ui/core";
import {
    VisibilityOutlined as VisibilityOutlinedIcon,
    VisibilityOffOutlined as VisibilityOffOutlinedIcon,
} from "@material-ui/icons";
import { LoginTextField } from "./LoginInputField";
import { useLoginStyles } from "./LoginStyles";
import { selectUserIsError } from "../../store/ducks/user/selectors";
import { fetchSignIn, setUserLoadingStatus } from "../../store/ducks/user/actionCreators";
import { ACCOUNT_FORGOT, ACCOUNT_SIGNIN } from "../../constants/path-constants";
import { LoadingStatus } from "../../types/common";
import { AppIcon,  } from '../../icons';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { APP_NAME } from '../../constants/common-constants';

const Login: FC = (): ReactElement => {
    const classes = useLoginStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const errorStatus = useSelector(selectUserIsError);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        history.replace({ state: {} });
        return () => {
            dispatch(setUserLoadingStatus(LoadingStatus.LOADING));
        };
    }, []);

    const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }

        // Trigger the reCAPTCHA v3 challenge
        const recaptchaToken = await executeRecaptcha();

        // Convert email to lowercase before sending it to the backend
        const formattedEmail = email.toLowerCase();

        dispatch(fetchSignIn({ email:formattedEmail, password, history, recaptchaToken }));
    };

    const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>): void => {
        setEmail(event.target.value);
    };

    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.target.value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className={classes.container}>
            <div>
                <AppIcon height={45} width={45} />
            </div>
            <Typography variant={"h4"} component={"div"}>
                Log in to {APP_NAME}
            </Typography>
            {(errorStatus) && (
                <Typography variant={"body1"} component={"div"} className={classes.error}>
                    The username and password you entered did not match our records.
                    Please double-check and try again.
                </Typography>
            )}
            <form onSubmit={onSubmit}>
                <div className={classes.input}>
                    <LoginTextField
                        label="Email or username"
                        type="email"
                        variant="filled"
                        onChange={handleChangeEmail}
                        value={email}
                        autoFocus={true}
                    />
                </div>
                <div className={classes.input}>
                    <LoginTextField
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        variant="filled"
                        onChange={handleChangePassword}
                        value={password}
                    />
                    <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                    </IconButton>
                </div>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={!(email && password)}
                    fullWidth
                >
                    Login
                </Button>
            </form>
            <div className={classes.footer}>
                <Typography variant={"body1"} component={"span"}>
                    <Link to={ACCOUNT_FORGOT}>
                        Forgot password?
                    </Link>
                </Typography>
                {" · "}
                <Typography variant={"body1"} component={"span"}>
                    <Link
                        to={{
                            pathname: ACCOUNT_SIGNIN,
                            state: "signUp"
                        }}
                    >
                        Sign up for {APP_NAME}
                    </Link>
                </Typography>
            </div>
        </div>
    );
};

const LoginWrapper: FC = (): ReactElement => (
    <GoogleReCaptchaProvider reCaptchaKey="6LeGej4qAAAAAABKGxLw5FY4DGAaquNMtfpxcQTy">
      <Login />
    </GoogleReCaptchaProvider>
);
  
export default LoginWrapper;


import produce, { Draft } from "immer";

import { UsersMentionsState } from "./contracts/state";
import { UsersMentionsListActions, UsersMentionsActionsType } from "./contracts/actionTypes";
import { LoadingStatus } from "../../../types/common";

export const initialUsersMentionsListState: UsersMentionsState = {
    users: [],
    userDetail: [],
    pagesCount: 1,
    loadingState: LoadingStatus.LOADING
};

export const usersMentionsListReducer = produce((draft: Draft<UsersMentionsState>, action: UsersMentionsListActions) => {
    switch (action.type) {
        case UsersMentionsActionsType.SET_USERS:
            draft.users = action.payload;
            draft.loadingState = LoadingStatus.LOADED;
            break;

        case UsersMentionsActionsType.RESET_USERS_STATE:
            draft.users = [];
            draft.userDetail = [];
            draft.pagesCount = 1;
            draft.loadingState = LoadingStatus.LOADING;
            break;

        case UsersMentionsActionsType.SET_USERS_LOADING_STATE:
            draft.loadingState = action.payload;
            break;

        case UsersMentionsActionsType.SET_MENTION_USER_DETAIL:
            draft.userDetail = action.payload;
            break;
        default:
            break;
    }
}, initialUsersMentionsListState);

import React, { ChangeEvent, FC, FormEvent, ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { IconButton, InputAdornment, Paper } from "@material-ui/core";

import { MainSearchTextField } from "../../components/SearchTextField/MainSearchTextField";
import {
    fetchLatestTweets,
    fetchMediaTweets,
    fetchTopTweets,
    fetchTweetsByTag,
    fetchTweetsByText,
    fetchTweetsWithVideo,
    resetTweets
} from "../../store/ducks/tweets/actionCreators";
import BackButton from "../../components/BackButton/BackButton";
import { selectPagesCount, selectTweetsItemsSize } from "../../store/ducks/tweets/selectors";
import { useExploreStyles } from "./ExploreStyles";
import { EditIcon, SearchIcon } from "../../icons";
import {
    fetchUsersSearch,
    fetchUsersSearchByUsername,
    resetUsersState
} from "../../store/ducks/usersSearch/actionCreators";
import { selectUsersPagesCount } from "../../store/ducks/usersSearch/selectors";
import { useGlobalStyles } from "../../util/globalClasses";
import { withDocumentTitle } from "../../hoc/withDocumentTitle";
import PageHeaderWrapper from "../../components/PageHeaderWrapper/PageHeaderWrapper";
import UsersList from "./UsersList/UsersList";
import TweetsList from "./TweetsList/TweetsList";
import {
    FetchTopTweetsActionInterface,
    FetchTweetsByTagActionInterface,
    FetchTweetsByTextActionInterface
} from "../../store/ducks/tweets/contracts/actionTypes";
import { fetchTrendingTweets, resetTrendingState } from "../../store/ducks/tags/actionCreators";
import { APP_NAME } from "../../constants/common-constants";
import TrendingList from './TrendingList/TrendingList';
import { selectTrendingItemsSize, selectTrendsPagesCount } from '../../store/ducks/tags/selectors';

const Explore: FC = (): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const classes = useExploreStyles();
    const dispatch = useDispatch();
		const tweetsSize = useSelector(selectTweetsItemsSize);
		const trendinSize = useSelector(selectTrendingItemsSize);
    const tweetsPagesCount = useSelector(selectPagesCount);
		const usersPagesCount = useSelector(selectUsersPagesCount);
		const trendingPagesCount = useSelector(selectTrendsPagesCount);
    const location = useLocation<{ tag: string | undefined; text: string | undefined }>();
    const history = useHistory();
    const [searchText, setSearchText] = useState<string>("");
    const [activeTab, setActiveTab] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);

    useEffect(() => {
        window.scrollTo(0, 0);

        const params = new URLSearchParams(location.search);
        const tag = params.get("tag");
        const text = params.get("q");

        if (tag) {
            setSearchText(decodeURIComponent(tag));
            fetchTweetsByStateText(fetchTopTweets({ search: tag, pageNumber: 0 }), tag);
            setActiveTab(0);
        } else if (text) {
            fetchTweetsByStateText(fetchTweetsByText({ text, pageNumber: 0 }), text);
        } else if (location?.state?.text) {
            setSearchText(decodeURIComponent(location.state.text));
            dispatch(fetchTweetsByText({ text: location.state.text, pageNumber: 0 }));
        } else if (location?.state?.tag) {
          setSearchText(decodeURIComponent(location.state.tag));
          fetchTweetsByStateText(fetchTweetsByTag({ tag: location.state.tag, pageNumber: 0 }), location.state.tag);
        } else {
            loadTweets(true);
        }

        return () => {
            dispatch(resetTweets());
            history.replace({ search: "" });
            history.replace({ state: {} });
        };
    }, [location.search]);

    const fetchTweetsByStateText = (
        fetch: FetchTweetsByTextActionInterface | FetchTweetsByTagActionInterface | FetchTopTweetsActionInterface,
        stateText: string
    ): void => {
        dispatch(fetch);
        setSearchText(decodeURIComponent(stateText));
        setPageNumber((prevState) => prevState + 1);
    };

    const loadTweets = (isResetPage = false): void => {
        const encodedText = encodeURIComponent(searchText);

        switch (activeTab) {
            case 0:
                dispatch(fetchTopTweets({ pageNumber: isResetPage ? 0 : pageNumber, search: isResetPage ? searchText : encodedText }));
                break;
            case 1:
                dispatch(fetchLatestTweets({ pageNumber: isResetPage ? 0 : pageNumber, search: isResetPage ? searchText : encodedText }));
                break;
            case 2:
                if (searchText) dispatch(fetchUsersSearchByUsername({ username: encodedText, pageNumber }));
                else dispatch(fetchUsersSearch(pageNumber));
                break;
            case 3:
                dispatch(fetchMediaTweets({ pageNumber, search: isResetPage ? searchText : encodedText }));
                break;
            case 4:
                dispatch(fetchTweetsWithVideo({ pageNumber, search: isResetPage ? searchText : encodedText }));
                break;
            case 5:
                dispatch(fetchTrendingTweets({ pageNumber: isResetPage ? 0 : pageNumber, search: searchText }));
                break;
            default:
                break;
        }

        setPageNumber((prevState) => prevState + 1);
    };

    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number): void => {
        setActiveTab(newValue);
    };

    const handleSubmitSearch = (event?: FormEvent<HTMLFormElement>): void => {
        event?.preventDefault();

        if (searchText) {
            switch (activeTab) {
                case 0:
                    handleShowItems(showTopTweets);
                    break;
                case 1:
                    handleShowItems(showLatestTweets);
                    break;
                case 2:
                    handleShowItems(showUsers);
                    break;
                case 3:
                    handleShowItems(showMediaTweets);
                    break;
                case 4:
                    handleShowItems(showTweetsWithVideos);
                    break;
                case 5:
                    handleShowItems(showTrendingTweets);
                    break;
                default:
                    break;
            }
        }
    };

    const handleSearchText = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setSearchText(event.target.value);
    };

    const handleShowItems = (callback: () => void): void => {
        window.scrollTo(0, 0);
        setPageNumber(0);
        dispatch(resetTweets());
				dispatch(resetUsersState());
				dispatch(resetTrendingState());
        callback();
        setPageNumber((prevState) => prevState + 1);
    };

    const showTopTweets = (): void => {
        dispatch(fetchTopTweets({ pageNumber: 0, search: searchText }));
    };

    const showTrendingTweets = (): void => {
        dispatch(fetchTrendingTweets({ pageNumber: 0, search: searchText }));
    };

    const showLatestTweets = (): void => {
        dispatch(fetchLatestTweets({ pageNumber: 0, search: searchText }));
    };

    const showUsers = (): void => {
        if (searchText) {
            console.log("user log", { username: searchText, pageNumber: 0 });
            dispatch(fetchUsersSearchByUsername({ username: encodeURIComponent(searchText), pageNumber: 0 }));
        } else {
            dispatch(fetchUsersSearch(0));
        }
    };

    const showMediaTweets = (): void => {
        dispatch(fetchMediaTweets({ pageNumber: 0, search: searchText }));
    };

    const showTweetsWithVideos = (): void => {
        dispatch(fetchTweetsWithVideo({ pageNumber: 0, search: searchText }));
		};

    const onBack = (): void => {
        const pathname = window.location.pathname;
        const searchParams = window.location.search;
        if (pathname === "/search" && searchParams) {
            setPageNumber(0);
            setSearchText("");
            if (activeTab === 2) {
                dispatch(fetchUsersSearch(0));
            } else if (activeTab === 3) {
                dispatch(fetchMediaTweets({ pageNumber: 0 }));
            } else if (activeTab === 4) {
                dispatch(fetchTweetsWithVideo({ pageNumber: 0 }));
            } else {
                dispatch(fetchTopTweets({ pageNumber: 0 }));
            }
        }
    };

    return (
      <Paper className={(globalClasses.pageContainer, classes.container)} variant="outlined">
          <div className={classes.headerWrapper}>
              <PageHeaderWrapper>
                      <form style={{ display: "flex" }} onSubmit={handleSubmitSearch}>
                          <div className={classes.backButtonWrapper}>
                              <BackButton onBack={onBack} />
                          </div>
                          <MainSearchTextField
                              variant="outlined"
                              placeholder={`Explore ${APP_NAME}`}
                              onChange={handleSearchText}
                              value={searchText}
                              InputProps={{
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          {SearchIcon}
                                      </InputAdornment>
                                  )
                              }}
                          />
                          <IconButton className={classes.editButton} color="primary" size="small">
                              <>{EditIcon}</>
                          </IconButton>
                      </form>
                      <div className={classes.tabs}>
                          <Tabs value={activeTab} onChange={handleChangeTab} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
                              <Tab onClick={() => handleShowItems(showTopTweets)} label="Top" />
                              <Tab onClick={() => handleShowItems(showLatestTweets)} label="Latest" />
                              <Tab onClick={() => handleShowItems(showUsers)} label="People" />
                              <Tab onClick={() => handleShowItems(showMediaTweets)} label="Photos" />
															<Tab onClick={() => handleShowItems(showTweetsWithVideos)} label="Videos" />
															<Tab onClick={() => handleShowItems(showTrendingTweets)} label="Trend" />
                          </Tabs>
                      </div>
                  {/* <div>
                  </div> */}
              </PageHeaderWrapper>
          </div>
          <div className={classes.contentWrapper}>
              <InfiniteScroll
                  // dataLength={tweetsSize}
                  // style={{ overflow: "unset" }}
                  style={{ minHeight: '100vh' }}
                  dataLength={tweetsSize || trendinSize}
                  next={loadTweets}
                  hasMore={pageNumber < (activeTab === 2 ? usersPagesCount : activeTab === 5 ? trendingPagesCount : tweetsPagesCount)}
                  loader={null}
              >
                  {(activeTab === 2) ? (
                      <UsersList />
									) : (activeTab === 5) ? (
										<TrendingList />
									) : (
                    <TweetsList />
                  )}
              </InfiniteScroll>
          </div>
      </Paper>
  );
};

export default withDocumentTitle(Explore)("Explore");

import React, { createContext, useContext, useState, ReactNode, useMemo } from "react";

// Create a Context for the popover state
interface PopoverContextProps {
    anchorEl: HTMLElement | null;
    setAnchorEl: (el: HTMLElement | null) => void;
}

const PopoverContext = createContext<PopoverContextProps | undefined>(undefined);

// Define the type for the provider's props (which includes children)
interface PopoverProviderProps {
    children: ReactNode; // ReactNode type is used to allow anything React can render (JSX, strings, etc.)
}

// Create a provider component with proper typing
export const PopoverProvider: React.FC<PopoverProviderProps> = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    // Memoize the context value to avoid unnecessary re-renders
    const value = useMemo(
        () => ({
            anchorEl,
            setAnchorEl
        }),
        [anchorEl]
    );

    return <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>;
};

// Custom hook to use the PopoverContext
export const usePopover = () => {
    const context = useContext(PopoverContext);
    if (!context) {
        throw new Error("usePopover must be used within a PopoverProvider");
    }
    return context;
};

import { makeStyles } from "@material-ui/core";
import { Height } from "@material-ui/icons";

export const useChangePhoneModalStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialogContent-root": {
            paddingTop: 16,
            paddingBottom: 16
        }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: 598,
        height: 650,
        position: "relative",
        overflowX: "hidden",
        padding: "0px 32px",
        "& .MuiTypography-h3": {
            padding: "12px 0px"
        },
        "& .MuiTypography-subtitle1, .MuiTypography-body1": {
            marginBottom: 20
        }
    },
    logoIcon: {
        margin: "16px auto 6px",
        textAlign: "center",
        "& svg": {
            height: "2.30em",
            color: theme.palette.primary.main
        }
    },
    infoWrapper: {
        marginTop: 38,
        display: "flex",
        justifyContent: "space-between"
    },
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    selectWrapper: {
        marginBottom: 20,
        "& .MuiFormLabel-root": {
            transform: "translate(12px, 10px) scale(0.75)"
        },
        "& .MuiFormControl-root": {
            width: "100%"
        }
    },
    footer: {
        width: "100%",
        marginTop: "auto",
        marginBottom: "32px",
        "& .MuiButton-root": {
            height: "42px",
        }
    }
}));
import { makeStyles } from '@material-ui/core';

export const useTrendingComponentStyle = makeStyles((theme) => ({
  container: {
    position: "relative",
    cursor: "pointer",
    // paddingTop: 12,
    // paddingLeft: 20,
    padding: 12,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderRadius: 0,
    "&:hover": {
        backgroundColor: theme.palette.secondary.main
    }
  },
  subTitle: {
    fontSize: 12,
    color: theme.palette.grey[800]
  }
}))
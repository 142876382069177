import { makeStyles } from "@material-ui/core";

export const useNewsStyles = makeStyles((theme) => ({
    header: {
        border: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start !important",
        gap: "5px",
        padding: "10px 0",
        overflow: "hidden"
    },
    categoryFilterList: {
        justifyContent: "space-evenly",
        alignItems: "center",
        overflowX: "auto",
        maxWidth: "100%",
        scrollbarWidth: "thin",
        paddingLeft: "13px"
    },
    newslist: {
        padding: "12px",
        marginTop: "2%"
    },
    section: {
        display: "flex",
        marginBottom: "16px",
        boxShadow: "0px 12px 35px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
    },
    image: {
        "& img": {
            width: "175px",
            height: "100%",
            boxSizing: "border-box",
            objectFit: "cover",
            borderRadius: "10px 0px 0px 10px",
        }
    },
    newsHeader: {
        display: "flex",
        justifyContent: "space-between",
        padding: "5px 0",
        fontWeight: "bold"
    },
    main: {
        boxSizing: "border-box",
        padding: "15px",
        flex: 1,
    },
    content: {
        flex: 1,
        textAlign: "justify"
    },
    footerText: {
        fontWeight: "bold"
    },
    infoChips: {
        display: "flex",
    },
    notFound: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "10vh",
        "& .MuiTypography-h5": {
            color: "#333333"
        }
    }
}));

import { Box, Typography } from "@material-ui/core";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { countries } from '../../constants/common-constants';
import { NewsListResponseInterface } from '../../types/news';
import { formatNewsPublishedDate } from "../../util/format-date-helper";
import { decodeHTMLEntities } from '../../util/text-formatter';
import { useNewsStyles } from "./NewStyles";

interface IProps {
    news: NewsListResponseInterface
}

const NewsDetail = ({ news }: IProps) => {
    const classes = useNewsStyles();
    const [isImageLoaded, setIsImageLoaded] = useState(true);

    const handleImageError = () => {
      setIsImageLoaded(false);
    };

    return (
        <Box component={`section`} sx={{ borderRadius: 10 }}>
            <div className={classes.section}>
                {(news.imageUrl && news.imageUrl !== "null" && isImageLoaded) && (
                    <div className={classes.image}>
                        <img
                            src={news.imageUrl}
                            alt={news.title}
                            onError={handleImageError}
                        />
                    </div>
                )}

                <div className={classes.main}>
                    <div className={classes.newsHeader}>
                        <div>{countries[news.country]}</div>
                        <Typography variant="subtitle2" className={classes.footerText}>
                            {formatNewsPublishedDate(new Date(news.publishedAt))}
                        </Typography>
                    </div>
                    <div className={classes.content}>
                        <Link to={{
                            pathname: news.url
                        }}
                        target='_blank'
                        >
                            <Typography variant="h6">{news.title}</Typography>
                        </Link>
                        <p>{decodeHTMLEntities(news.description)}</p>
                    </div>
                    <div>
                        {news.author && news.author != "null" && (
                            <Typography variant="caption" className={classes.footerText}>
                                Author: {news.author},&nbsp;
                            </Typography>
                        )}
                        <Typography variant="caption" className={classes.footerText}>
                            {news.source}
                        </Typography>
                    </div>
                </div>
                
            </div>
        </Box>
    );
};

export default NewsDetail;

import { Action } from 'redux';
import { LaunchSoonPayload } from './state';

export enum LaunchSoonActionTypes {
    PROCESS_PRE_PROCESS_REGISTRATION = "launchSoon/PROCESS_PRE_PROCESS_REGISTRATION"
}

export interface ProcessPreProcessRegistrationActionInterface extends Action<LaunchSoonActionTypes> {
    type: LaunchSoonActionTypes.PROCESS_PRE_PROCESS_REGISTRATION;
    payload: LaunchSoonPayload;
}

export type LaunchSoonActions =
    | ProcessPreProcessRegistrationActionInterface
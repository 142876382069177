import { useSelector } from "react-redux";
import { selectIsTrendsLoading, selectTrendingItems } from "../../../store/ducks/tags/selectors";
import Spinner from "../../../components/Spinner/Spinner";
import { List } from "@material-ui/core";
import TrendingComponent from "../../../components/TrendingComponent/TrendingComponent";

const TrendingList = () => {
    const isLoading = useSelector(selectIsTrendsLoading);
    const trendings = useSelector(selectTrendingItems);

    return (
        <>
            <List>
                {trendings.map((trend) => (
                    <TrendingComponent key={trend.id} trend={trend} />
                ))}
            </List>
            {isLoading && <Spinner />}
        </>
    );
};

export default TrendingList;

import React, { FC, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Link as MuiLink, Typography } from "@material-ui/core";

import { useCreateAccountModalStyles } from "./CreateAccountModalStyles";
import { RegistrationInputField } from "../RegistrationInput/RegistrationInputField";
import Spinner from "../../../components/Spinner/Spinner";
import DialogWrapper from "../DialogWrapper/DialogWrapper";
import {
    selectIsLoading,
    selectRegistrationInfo,
    selectRegistrationStep3
} from "../../../store/ducks/authentication/selector";
import { fetchSendRegistrationCode } from "../../../store/ducks/authentication/actionCreators";

const CreateAccountModal: FC = (): ReactElement => {
    const classes = useCreateAccountModalStyles();
    const dispatch = useDispatch();
    const registrationInfo = useSelector(selectRegistrationInfo);
    const registrationStep3 = useSelector(selectRegistrationStep3);
    const isLoading = useSelector(selectIsLoading);

    const onSubmit = async (): Promise<void> => {
        dispatch(fetchSendRegistrationCode(registrationInfo));
    };

    return (
        <DialogWrapper isOpen={registrationStep3} logo={true}>
            <Typography component={"div"} className={classes.title}>
                Step 3 of 6
            </Typography>
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <Typography variant={"h3"} component={"div"} className={classes.subtitle}>
                        Create your account
                    </Typography>
                    <div className={classes.form}>
                        <RegistrationInputField
                            label="Username"
                            variant="filled"
                            value={registrationInfo.username}
                            fullWidth
                            disabled
                        />
                        <RegistrationInputField
                            label="Email"
                            variant="filled"
                            value={registrationInfo.email}
                            fullWidth
                            disabled
                        />
                        <RegistrationInputField
                            label="Birth date"
                            variant="filled"
                            value={registrationInfo.birthday}
                            fullWidth
                            disabled
                        />
                    </div>
                    <Typography variant={"body1"} component={"div"} className={classes.text}>
                        {"By signing up, you agree to the "}
                        <MuiLink variant="body1" target="_blank" rel="noopener">
                            Terms of Service
                        </MuiLink>
                        {" and "}
                        <MuiLink variant="body1" target="_blank" rel="noopener">
                            Privacy Policy
                        </MuiLink>
                        {", including "}
                        <MuiLink variant="body1" target="_blank" rel="noopener">
                            Cookie Use
                        </MuiLink>
                        {". Others will be able to find you by email or phone number when provided "}
                        <MuiLink variant="body1" target="_blank" rel="noopener">
                            Privacy Options
                        </MuiLink>
                        {"."}
                    </Typography>
                    <Button className={classes.button} onClick={onSubmit} variant="contained" color="primary" size="small" fullWidth>
                        Sign up
                    </Button>
                </>
            )}
        </DialogWrapper>
    );
};

export default CreateAccountModal;
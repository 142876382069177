import { call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { FetchUserMentionsActionInterface, FetchUsersMentionsListActionInterface, UsersMentionsActionsType } from "./contracts/actionTypes";
import { UserApi } from "../../../services/api/user-service/userApi";
import { setMentionUserDeail, setUsersMentionsList, setUsersMentionsListLoadingState } from "./actionCreators";
import { UserResponse } from "../../../types/user";
import { LoadingStatus } from "../../../types/common";

export function* fetchUserMentionLists({ payload }: FetchUsersMentionsListActionInterface) {
    try {
        yield put(setUsersMentionsListLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<UserResponse[]> = yield call(UserApi.getUerMentionsList, payload.username);
        yield put(setUsersMentionsList(response.data));
    } catch (error) {
        yield put(setUsersMentionsListLoadingState(LoadingStatus.ERROR));
    }
}

export function* fetchUserSearchByUsernameRequest({ payload }: FetchUserMentionsActionInterface) {
    try {
        yield put(setUsersMentionsListLoadingState(LoadingStatus.LOADING));
        const response: AxiosResponse<UserResponse[]> = yield call(UserApi.searchUsersByUsername, {...payload, pageNumber: 0});
        yield put(setMentionUserDeail(response.data));
    } catch (error) {
        yield put(setUsersMentionsListLoadingState(LoadingStatus.ERROR));
    }
}

export function* usersMentionsSaga() {
    yield takeLatest(UsersMentionsActionsType.FETCH_USER_MENTION_LIST, fetchUserMentionLists);
    yield takeLatest(UsersMentionsActionsType.FETCH_MENTION_USER_DETAIL, fetchUserSearchByUsernameRequest);
}

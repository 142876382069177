import { Typography } from '@material-ui/core';
import { useNewsStyles } from './NewStyles';

const NoNewsFound = () => {
    const classes = useNewsStyles();

    return (
        <div className={classes.notFound}>
            <Typography variant='h5'>No records found</Typography>
        </div>
    )
}

export default NoNewsFound;
import { Action } from "redux";
import { LoadingStatus, PageableResponse } from '../../../../types/common';
import { FetchNewsRequestState, NewsState } from './state';

export enum NewsActionsType {
    SET_NEWS = "news/SET_NEWS",
    APPEND_NEWS = "news/APPEND_NEWS",
    GET_NEWS = "news/GET_NEWS",
    RESET_NEWS = "news/RESET_NEWS",
    SET_LOADING_STATE = "news/SET_LOADING_STATE"
}

export interface SetNewsLoadingStateActionInterface extends Action<NewsActionsType> {
    type: NewsActionsType.SET_LOADING_STATE;
    payload: LoadingStatus;
}

export interface FetchNewsActionInterface extends Action<NewsActionsType> {
    type: NewsActionsType.GET_NEWS,
    payload: Partial<FetchNewsRequestState>
}

export interface SetNewsActionInterface extends Action<NewsActionsType> {
    type: NewsActionsType.SET_NEWS,
    payload: PageableResponse<NewsState["news"]>
}

export interface ResetNewsActionInterface extends Action<NewsActionsType> {
    type: NewsActionsType.RESET_NEWS
}

export type NewsActions =
    SetNewsLoadingStateActionInterface
    | FetchNewsActionInterface
    | SetNewsActionInterface
    | ResetNewsActionInterface
import axios, {AxiosResponse} from 'axios'
import { NewsResponseInterface } from '../../../types/news'
import { UI_V1_NEWS_FETCH } from '../../../constants/endpoint-constants'

export const NewsApi = {
    async fetchNews(page?: number, size?: number, categories?: string, languages?: string, countries?: string): Promise<AxiosResponse<NewsResponseInterface>> {
        return await axios.get<NewsResponseInterface>(UI_V1_NEWS_FETCH, {
            params: {
                page,
                size,
                categories,
                languages,
                countries
            }
        })
    } 
} 
import { Action } from "redux";
import {
    UserMentionListRequest, UsersMentionsState,
} from "./state";
import { LoadingStatus } from "../../../../types/common";

export enum UsersMentionsActionsType {
    SET_USERS = "usersMentions/SET_USERS",
    RESET_USERS_STATE = "usersMentions/RESET_USERS_STATE",
    SET_USERS_LOADING_STATE = "usersMentions/SET_USER_LOADING_STATE",
    FETCH_USER_MENTION_LIST = "usersMentions/FETCH_USER_MENTION_LIST",
    FETCH_MENTION_USER_DETAIL = "usersMentions/FETCH_MENTION_USER_DETAIL",
    SET_MENTION_USER_DETAIL = "usersMentions/SET_MENTION_USER_DETAIL"
}

export interface SetUsersMentionsListActionInterface extends Action<UsersMentionsActionsType> {
    type: UsersMentionsActionsType.SET_USERS;
    payload: UsersMentionsState["users"];
}

export interface ResetUsersStateActionInterface extends Action<UsersMentionsActionsType> {
    type: UsersMentionsActionsType.RESET_USERS_STATE;
}

export interface SetUsersMentionsListLoadingStatusActionInterface extends Action<UsersMentionsActionsType> {
    type: UsersMentionsActionsType.SET_USERS_LOADING_STATE;
    payload: LoadingStatus;
}

export interface FetchUsersMentionsListActionInterface extends Action<UsersMentionsActionsType> {
    type: UsersMentionsActionsType.FETCH_USER_MENTION_LIST;
    payload: UserMentionListRequest;
}

export interface FetchUserMentionsActionInterface extends Action<UsersMentionsActionsType> {
    type: UsersMentionsActionsType.FETCH_MENTION_USER_DETAIL;
    payload: UserMentionListRequest;
}

export interface SetMentionUserDetailActionInterface extends Action<UsersMentionsActionsType> {
    type: UsersMentionsActionsType.SET_MENTION_USER_DETAIL;
    payload: UsersMentionsState["users"];
}

export type UsersMentionsListActions =
    | SetUsersMentionsListActionInterface
    | ResetUsersStateActionInterface
    | SetUsersMentionsListLoadingStatusActionInterface
    | FetchUsersMentionsListActionInterface
    | FetchUserMentionsActionInterface
    | SetMentionUserDetailActionInterface;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import { fetchNews, resetNews } from "../../store/ducks/news/actionCreators";
import { selectIsNewsLoading, selectNewsList, selectNewsPagesCount } from "../../store/ducks/news/selectors";
import InfiniteScrollWrapper from "../../components/InfiniteScrollWrapper/InfiniteScrollWrapper";
import { Chip, List, Paper, Typography } from "@material-ui/core";
import classnames from "classnames";
import NewsDetail from "./NewsDetail";
import { useGlobalStyles } from "../../util/globalClasses";
import { useNewsStyles } from "./NewStyles";
import { NEWS_CATEGORY_LIST } from "../../constants/common-constants";
import { capitalize } from "../../util/text-formatter";
import Spinner from '../../components/Spinner/Spinner';
import NoNewsFound from './NotNewsFound';

const News = () => {
    const dispatch = useDispatch();
    const globalClasses = useGlobalStyles({});
    const classes = useNewsStyles();
    const newsList = useSelector(selectNewsList);
    const isLoading = useSelector(selectIsNewsLoading);
    const pagesCount = useSelector(selectNewsPagesCount);
    const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        dispatch(resetNews());
    }, []);

    const loadNewsList = (): void => {
        dispatch(fetchNews({ page, size: 20, categories: selectedCategory ? selectedCategory.join(",") : undefined }));
        setPage((prevState) => prevState + 1);
    };

    const handleCategoryFilter = (category: string) => {
        setSelectedCategory((prevItem: string[]) => {
            if (prevItem.includes(category)) {
                return prevItem.filter((item: string) => item !== category);
            } else {
                return [...prevItem, category];
            }
        });
        window.scrollTo(0, 0);
        setPage(0)
        dispatch(resetNews());
    };

    useEffect(() => {
        if (selectedCategory.length || selectedCategory.length === 0) {
            loadNewsList()
        }
    }, [selectedCategory])
    return (
        <Paper className={globalClasses.pageContainer} variant="outlined">
            <Paper className={classnames(globalClasses.pageHeader, classes.header)}>
                <div className={globalClasses.pageHeaderTitleWrapper}>
                    <Typography variant="h5">News</Typography>
                </div>
                <Stack direction="row" spacing={1} className={classes.categoryFilterList}>
                    {NEWS_CATEGORY_LIST.map((category) => (
                        <Chip
                            label={capitalize(category)}
                            variant={selectedCategory.includes(category) ? "default" : "outlined"}
                            size="small"
                            color="primary"
                            onClick={() => handleCategoryFilter(category)}
                        />
                    ))}
                </Stack>
            </Paper>
            <div className={globalClasses.contentWrapper}>
                <InfiniteScrollWrapper dataLength={newsList.length} pagesCount={pagesCount} loadItems={loadNewsList}>
                    <List component="nav" className={classes.newslist}>
                        {newsList.map((news, i: number) => (
                            <NewsDetail key={news.title} news={news} />
                        ))}
                        {isLoading && <Spinner />}
                        {!newsList.length && !isLoading && <NoNewsFound />}
                    </List>
                </InfiniteScrollWrapper>
            </div>
        </Paper>
    );
};

export default News;

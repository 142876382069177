import { makeStyles } from "@material-ui/core";

export const useLaunchSoontyles = makeStyles((theme) => ({
    body: {
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        background: "linear-gradient(180deg, #52B5FB4A 29%, #B8E0FD59 35%, #FFFFFF 100%) no-repeat",
        margin: "0",
        padding: "0"
    },
    container: {
        width: "100%",
        height: "calc(100vh - 50px)",
        zIndex: 2,
        position: "relative",
        "& .MuiTypography-h4": {
            fontWeight: 700,
            marginTop: "12px",
        },
        // "& .MuiTypography-h3": {
        //     fontSize: "2rem",
        //     fontWeight: 700,
        //     marginBottom: "18px"
        // },
        // "& .MuiTypography-subtitle1": {
        //     wordSpacing: "2px !important",
        //     lineHeight: "25px !important",
        // }
    },
    blueBirdIcon: {
        display: "flex",
        paddingTop: "6%",
        alignItems: "center",    /* Centers vertically */
        flexDirection: "column",
        zIndex: 2,
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            "& svg": {
                width: "40%"
            }
        },
        "& svg": {
            width: "179px",
            height: "148px"
        }
    },
    header: {
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            "& svg": {
                width: "40%"
            }
        }

    },
    headerIconTopLeft: {
        position: "absolute",
        top: "-5px",
        left: "-5px"
    },
    headerIconCenter: {
        position: "absolute",
        top: "50%",
        left: "15%"
    },
    headerIconRight: {
        position: "absolute",
        top: "75%",
        right: "2%",
        opacity: 0.7,
        [theme.breakpoints.down("sm")]: {
            right: "-60%"
        }
    },
    heading: {
        color: "#1F3747",
        textAlign: "center",
    },
    bodyHeading: {
        fontSize: "2rem",
        fontWeight: 700,
        marginBottom: "18px",
        color: "#333333",
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
            fontSize: "1.5rem",
            marginBottom: "6px",
        }
    },
    subTitle: {
        color: "#333333",
        textAlign: "center",
        fontSize: "20px",
        wordSpacing: "2px",
        lineHeight: "25px",
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px"
        }
    },
    contentBody: {
        marginTop: "24px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "0 24px"
    },
    papper: {
        padding: "2px 2px",
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "flex-end",
        maxWidth: "650px",
        border: "1px solid #D2D0D0",
        borderRadius: "10px",
        // borderColor: "#D2D0D0",
        boxShadow: "0px 0px 15px 0px #0000001A",
        [theme.breakpoints.up("md")]: {
            width: "550px"
        },
        [theme.breakpoints.down("md")]: {
            width: "max-content"
        }
    },
    formContorl: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexDirection: "row"
    },
    inputBase: {
        marginLeft: 2,
        flex: 1,
        fontSize: "16px",
        padding: "2px 2px",
        borderRadius: "10px",
        "& .MuiInputBase-input": {
            padding: "12px 8px"
        }
    },
    devider: {
        height: 28,
        marginInline: "6px",
        backgroundColor: "#D2D0D0"
    },
    paperPlane: {
      padding: "8px",
      marginRight: "6px",
      "& .MuiIconButton-label": {
          transform: "rotate(45deg)",
      }
    },
    footer: {
        display: "flex",
        alignItems: "flex-end",
        marginBottom: "3%",
        justifyContent: "center"
    },
    copyright: {
        textDecoration: "none",
        color: "#1D9BF0"
    }
}));

import React, { FC, ReactElement } from "react";
import { ListItem, Typography } from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { HOME } from '../../../constants/path-constants';
import { LinkIcon } from "../../../icons";
import { setOpenSnackBar } from "../../../store/ducks/actionSnackbar/actionCreators";

interface CopyLinkToTweetButtonProps {
    closeShareTweet: () => void;
    tweetId: number; 
}

const CopyLinkToTweetButton: FC<CopyLinkToTweetButtonProps> = ({ closeShareTweet, tweetId }): ReactElement => {
    const dispatch = useDispatch();
    const APP_URL = window.location.origin;

    const onCopyLinkToTweet = (): void => {
        dispatch(setOpenSnackBar("Copied to clipboard"));
        closeShareTweet();
    };

    return (
      <CopyToClipboard text={`${APP_URL}${HOME}/tweet/${tweetId}`}>
          <ListItem id={"copyLinkToTweet"} onClick={onCopyLinkToTweet}>
              <>{LinkIcon}</>
              <Typography variant={"body1"} component={"span"}>
                  Copy link to Tweet
              </Typography>
          </ListItem>
      </CopyToClipboard>
    );
};

export default CopyLinkToTweetButton;

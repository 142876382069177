import { Avatar, Button, Divider, List, ListItem, ListItemIcon, Paper, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Popover from '@material-ui/core/Popover';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import React, { FC, memo, ReactElement, useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModalWindow } from "../../hook/useModalWindow";
import { HelpCircleIcon } from "../../icons";
import {
  fetchVeracityInfo,
} from "../../store/ducks/tweetAdditionalInfo/actionCreators";
import {
  selectVeracityList,
  selectVeracityTweetInfoLoading,
} from "../../store/ducks/tweetAdditionalInfo/selectors";
import { LoadingStatus } from '../../types/common';
import { useGlobalStyles } from "../../util/globalClasses";
import ActionIconButton from "../ActionIconButton/ActionIconButton";
import DialogTitleComponent from "../DialogTitleComponent/DialogTitleComponent";
import Spinner from "../Spinner/Spinner";
import { useVeracityModalStyles } from "./VeracityStyles";

interface VeracityTweetProps {
    tweetId: number;
    text: string;
    userId: number;
    userAvatar?: string;
}

const VeracityIconButton: FC<VeracityTweetProps> = memo(({ tweetId, text, userId, userAvatar }): ReactElement => {
    const globalClasses = useGlobalStyles({});
    const classes = useVeracityModalStyles();
    const dispatch = useDispatch();
    const { visibleModalWindow, onOpenModalWindow, onCloseModalWindow } = useModalWindow();
    const veracityList = useSelector(selectVeracityList);
    const veracityTweetInfoLoading = useSelector(selectVeracityTweetInfoLoading);
    const [selectedTweetId, setSelectedTweetId] = useState<number>(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [veracityTweetInfo, setVeracityTweetInfo] = React.useState<any>(null);
    const [referenceLinks, setReferenceLinks] = React.useState<string[]>([]);
    const [showReasoning, setShowReasoning] = useState<boolean>(false);

    const handleClick = () => {
      setSelectedTweetId(tweetId);
      setVeracityTweetInfo(null);
      dispatch(fetchVeracityInfo({
        tweet: text,
        tweet_id: tweetId,
        userId
      }));
      onOpenModalWindow();
    }

    const handlePopoverClick = (event: any, index: number) => {
      setShowReasoning(false);
      setAnchorEl(event.currentTarget);
      // eslint-disable-next-line no-mixed-operators
      const links = veracityTweetInfo?.reference_links?.length && veracityTweetInfo?.reference_links?.[index] || [];
      setReferenceLinks(links);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
      if (selectedTweetId === tweetId && veracityTweetInfoLoading === LoadingStatus.LOADED) {
        setVeracityInfo(tweetId);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTweetId, onOpenModalWindow, veracityTweetInfoLoading, tweetId]);

    useEffect(() => {
      if (veracityTweetInfo) {
        calculationTweetScore();
        calculationUserScore();
      }
    }, [veracityTweetInfo])

    const setVeracityInfo = (tweetId: number | string) => {
      const data = veracityList.filter((info) => info.tweetId === tweetId)
      if (data && data.length) {
        setVeracityTweetInfo(data[0]);
      }
    }

    const showVeracityInfo = (tweetId: number | string) => {
      setVeracityInfo(tweetId);
      onOpenModalWindow();
    }

    const onClose = () => {
      setSelectedTweetId(0);
      onCloseModalWindow();
    }

    const setItemIcon = (index: number) => {
      if (veracityTweetInfo?.verified[index]) {
        return (
          <ListItemIcon>
            <CheckIcon fontSize="small" style={{ color: "rgb(82, 181, 251)" }} />
          </ListItemIcon>
        )
      }
      return (
        <ListItemIcon>
        <ClearIcon fontSize="small" style={{ color: "red" }} />
      </ListItemIcon>
      )
    }

    const calculationTweetScore = () => {
      if (veracityTweetInfo?.tweet_score) {
        return Math.floor(veracityTweetInfo?.tweet_score);
      }
      return 0;
    }

    const calculationUserScore = () => {
      if (veracityTweetInfo?.userScore) {
        return Math.floor(veracityTweetInfo?.userScore);
      }
      return 0;
    }

    const getScoreLabel = () => {
      const score = calculationTweetScore();
      if (score >= 90) {
        return 'This post can be trusted';
      } else if (score >= 70) {
        return 'This post can be somewhat trusted';
      } else {
        return 'This post can not be trusted';
      }
    };

    const getUserScoreLabel = () => {
      const score = calculationUserScore();
      if (score >= 90) {
        return {
          text: "This user can typically be trusted",
          label: "Trusted"
        };
      } else if (score >= 70) {
        return {
          text: "This user can typically be somewhat trusted",
          label: "Somewhat Trusted"
        };
      } else {
        return {
          text: "This user can not typically be trusted",
          label: "Not Trusted"
        };
      }
    }

    const getColor = (score: number) => {
      if (score >= 90) {
        return "#24BB10";
      } else if (score >= 70) {
        return "#D9D9D9";
      } else {
        return "#EC1111";
      }
    };

    const clickReason = (event: any) => {
      setShowReasoning(true);
      setAnchorEl(event.currentTarget);
      setReferenceLinks(veracityTweetInfo?.reasoning);
    }

    const getTweetScore = (tweetId: number) => {
      const data = veracityList.find((info) => info.tweetId === tweetId);
      return data ? data.tweet_score : null;
    };

    const VeracityIcon = ({ tweetId }: { tweetId: number }) => {
      const tweetScore = useMemo(() => getTweetScore(tweetId), [tweetId]);
    
      const handleIconClick = useCallback(() => {
        showVeracityInfo(tweetId);
      }, [tweetId]);
    
      if (tweetScore === null) {
        return (
          <ActionIconButton
            actionText={"Veracity"}
            onClick={handleClick}
            size={"small"}
            icon={HelpCircleIcon}
          />
        );
      }

      return (
        <span className={classes.tweetScore} onClick={handleIconClick}>
          <span style={{ backgroundColor: getColor(tweetScore) }}></span>
          {tweetScore}
        </span>
      );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <>
        <div className={classes.root}>
          <VeracityIcon tweetId={tweetId} />
        </div>
        <Dialog className={classes.content} open={visibleModalWindow} onClose={onClose}>
          <DialogTitleComponent title={'Post Report'} onClose={onClose} />
          <DialogContent className={classes.dialogContent}>
            {veracityTweetInfoLoading === LoadingStatus.LOADING ? (
              <Spinner paddingTop={90} />
            ): (
              <>
                <Paper className={classes.container}>
                  <div className={classes.postCounter} style={{backgroundColor: getColor(calculationTweetScore())}}>
                    <span>{calculationTweetScore()}</span>
                  </div>
                  <div className={classes.postReport}>
                    <Typography variant={"h3"}>
                        {getScoreLabel()}
                    </Typography>
                    {calculationTweetScore() !== 0 && (
                      <List className={classes.postReportList}>
                        {veracityTweetInfo?.claims.map((item: string, index: number) => (
                          <ListItem key={index}>
                            {setItemIcon(index)}
                            <Typography variant={"h6"} style={{ marginLeft: "4px", fontWeight: 400 }}>
                              {item}
                              <span className={classes.referenceLink} onClick={(event) => handlePopoverClick(event, index)} style={{ marginLeft: "2px", color: "blue" }}>
                                View reference
                              </span>
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    )}
                    {(veracityTweetInfo?.reasoning?.length > 0 && calculationTweetScore() !== 0) && ( 
                      <Button
                      onClick={clickReason} variant="contained"
                      style={{ margin: "12px auto 0", width: 'max-content'}}
                        color="primary">
                        View Reasoning
                      </Button>
                    )}
                  </div>
                </Paper>
                <Divider className={classes.divider} />
                <Paper className={classes.userContainer}>
                  <div className={classes.userCountReport}>
                    <Typography variant={"body1"} className={classes.userScoreCount} style={{backgroundColor: getColor(calculationUserScore())}}>
                      {calculationUserScore()}
                    </Typography>
                    <Typography variant={"h3"}>
                      {getUserScoreLabel().text}
                    </Typography>
                  </div>
                  <div className={classes.userProfileReport}>
                    <Typography variant={"body1"} className={classes.userAvatar}>
                      <Avatar className={globalClasses.avatar} src={userAvatar} alt={`avatar ${userId!}`} />
                    </Typography>
                    <Typography variant={"h6"}>
                      {getUserScoreLabel().label}: This tweet appears unreliable and may contain false or misleading information. It does not match trusted sources and should be approached with caution.
                    </Typography>
                  </div>
                  <div className={classes.stepperline}>
                  </div>
                </Paper>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  style={{ maxWidth: '350px' }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                >
                  {referenceLinks.map((link, index) => (
                    <Typography className={classes.typography} key={index}>
                      {`${index + 1}. `}
                      {showReasoning ? (
                        link
                      ) : (
                        <a className={classes.referenceLink} target="_blank" href={link} rel="noreferrer">
                          {link}
                        </a>
                      )}
                    </Typography>
                  ))}
                </Popover>
              </>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
});

export default VeracityIconButton;

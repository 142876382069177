import { makeStyles } from "@material-ui/core";

export const useCreateAccountModalStyles = makeStyles(() => ({
    title: {
        fontSize: 18,
        fontWeight: 800,
        marginBottom: 22,
        linHeight: "24px"
    },
    subtitle: {
        fontWeight: 700
    },
    form: {
        "& .MuiFormControl-root": {
            marginTop: 24
        }
    },
    text: {
        marginTop: 15,
        marginBottom: 15
    },
    button: {
        // maxWidth: 490,
        position: "static",
        bottom: 0,
        marginBottom: 15,
        padding: "16px",
        height: "unset"
    }
}));
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import "emoji-mart/css/emoji-mart.css";
import { ChangeEvent, FC, ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profanity } from '@2toad/profanity';
import { useParams } from "react-router-dom";
import { MAX_TEXT_LENGTH } from "../../constants/common-constants";
import { usePopover } from '../../context/PopoverContext';
import { useInputText } from "../../hook/useInputText";
import { TweetApi } from "../../services/api/tweet-service/tweetApi";
import { setOpenSnackBar } from "../../store/ducks/actionSnackbar/actionCreators";
import { resetAddTweetFormState, setImages, setScheduleDate } from "../../store/ducks/addTweetForm/actionCreators";
import {
  selectGif,
  selectImageDescription,
  selectImages,
  selectPollData,
  selectReplyType,
  selectScheduledDate,
  selectSelectedUsers,
  selectVisiblePoll
} from "../../store/ducks/addTweetForm/selector";
import { fetchReplyTweet } from "../../store/ducks/tweet/actionCreators";
import {
  addPoll,
  addQuoteTweet,
  addScheduledTweet,
  addTweet,
  setTweetSentState,
  updateScheduledTweet
} from "../../store/ducks/tweets/actionCreators";
import { TweetSentState } from '../../store/ducks/tweets/contracts/state';
import { selectIsTweetSentStateError, selectIsTweetSentStateSent } from '../../store/ducks/tweets/selectors';
import { fetchUserMentionLists, resetUsersMentionsState } from "../../store/ducks/usersMentions/actionCreators";
import { selectUsersMentionsList } from "../../store/ducks/usersMentions/selectors";
import { Image } from "../../types/common";
import { BaseListResponse } from "../../types/lists";
import { QuoteTweetResponse, TweetResponse } from "../../types/tweet";
import { formatScheduleDate } from "../../util/format-date-helper";
import { debounce } from '../../util/utils-helper';
import GifImage from "../GifImage/GifImage";
import Quote from "../Quote/Quote";
import TweetListComponent from "../TweetListComponent/TweetListComponent";
import UploadImages from "../UploadImages/UploadImages";
import { useAddTweetFormStyles } from "./AddTweetFormStyles";
import AddTweetImage from "./AddTweetImage/AddTweetImage";
import EmojiIconButton from "./EmojiIconButton/EmojiIconButton";
import GifIconButton from "./GifIconButton/GifIconButton";
import MentionsList from "./MentionsList/MentionsLIst";
import Poll from "./Poll/Poll";
import PollIconButton from "./PollIconButton/PollIconButton";
import ProfileAvatar from "./ProfileAvatar/ProfileAvatar";
import Reply from "./Reply/Reply";
import ScheduleDateInfo from "./ScheduleDateInfo/ScheduleDateInfo";
import ScheduleIconButton from "./ScheduleIconButton/ScheduleIconButton";
import TextCountProgress from "./TextCountProgress/TextCountProgress";

export interface AddTweetFormProps {
    unsentTweet?: TweetResponse;
    quoteTweet?: QuoteTweetResponse;
    tweetList?: BaseListResponse;
    maxRows?: number;
    minRows?: number;
    tweetId?: number;
    title: string;
    buttonName: string;
    addressedUsername?: string;
    addressedId?: number;
    onCloseModal?: () => void;
}

export interface ImageObj {
    src: string;
    file: File;
}

const AddTweetForm: FC<AddTweetFormProps> = ({
    unsentTweet,
    quoteTweet,
    tweetList,
    maxRows,
    minRows,
    tweetId,
    title,
    buttonName,
    addressedUsername,
    addressedId,
    onCloseModal
}): ReactElement => {
    const classes = useAddTweetFormStyles();
    const dispatch = useDispatch();
    const params = useParams<{ userId: string }>();
    const visiblePoll = useSelector(selectVisiblePoll);
    const pollData = useSelector(selectPollData);
    const gif = useSelector(selectGif);
    const scheduledDate = useSelector(selectScheduledDate);
    const replyType = useSelector(selectReplyType);
    const imageDescription = useSelector(selectImageDescription);
    const images = useSelector(selectImages);
    const selectedUsers = useSelector(selectSelectedUsers);
    const usersMentionsList = useSelector(selectUsersMentionsList);
    const [cursorPosition, setCursorPosition] = useState<number>(0);
    const { text, setText, addEmoji, textConverter } = useInputText();
    const { setAnchorEl } = usePopover();
    const isTweetSentStateSent = useSelector(selectIsTweetSentStateSent);
    const isTweetSentStateError = useSelector(selectIsTweetSentStateError);

    useEffect(() => {
      dispatch(resetUsersMentionsState());
    }, []);

    useEffect(() => {
        if (isTweetSentStateSent) {
            dispatch(
                setOpenSnackBar(scheduledDate
                ? `Your Tweet will be sent on ${formatScheduleDate(scheduledDate)}`
                : "Your Tweet was sent."));
        } else if (isTweetSentStateError) {
            dispatch(setOpenSnackBar("Oops! Something went wrong!"));
        }

        return () => {
            dispatch(setTweetSentState(TweetSentState.IDEL));
        }
    }, [isTweetSentStateSent, isTweetSentStateError])
    useEffect(() => {
        if (unsentTweet) {
            setText(unsentTweet.text);
            dispatch(setScheduleDate(new Date(unsentTweet.scheduledDate)));
            if (unsentTweet.images?.length !== 0) {
                const newImages = [...images];
                const newImage = { ...images[0] };
                newImage.src = unsentTweet.images[0].src;
                newImages[0] = newImage;
                dispatch(setImages(newImages));
            }
        }
    }, [unsentTweet]);

    const handleClickAddTweet = async (): Promise<void> => {
        const tweet = await tweetPreProcessing();
        if (tweet) {
            if (visiblePoll) {
                const { day, hour, minute, choice1, choice2, choice3, choice4 } = pollData;
                const pollDateTime = day * 1440 + hour * 60 + minute;
                const choices = [choice1, choice2, choice3, choice4].filter((item) => item);
                dispatch(addPoll({ ...tweet, pollDateTime, choices }));
            } else if (scheduledDate !== null && unsentTweet === undefined) {
                dispatch(addScheduledTweet({ ...tweet, scheduledDate }));
            } else if (unsentTweet) {
                dispatch(updateScheduledTweet({ ...tweet, id: unsentTweet?.id }));
                if (onCloseModal) onCloseModal();
            } else {
                dispatch(addTweet(tweet));
            }
            if (onCloseModal) onCloseModal();
            tweetPostProcessing();
        }
    };

    const handleClickQuoteTweet = async (): Promise<void> => {
        const tweet = await tweetPreProcessing();
        if (tweet) {
            dispatch(addQuoteTweet({ ...tweet, tweetId: quoteTweet!.id, userId: params.userId }));
            tweetPostProcessing();
        }
       
    };

    const handleClickReplyTweet = async (): Promise<void> => {
        const tweet = await tweetPreProcessing();
        if (tweet) {
            dispatch(
                fetchReplyTweet({
                    ...tweet,
                    tweetId: tweetId!,
                    userId: params.userId,
                    addressedUsername: addressedUsername!,
                    addressedId: addressedId!
                })
            );
            tweetPostProcessing();
        }
        
    };

    const tweetPreProcessing = async () => {
        let result: Array<Image> = [];
        const text = textConverter();
        const isCensoredText = profanity.exists(text);
        console.log("🚀 ~ tweetPreProcessing ~ isCensoredText:", isCensoredText)
        if (isCensoredText) {
            dispatch(setOpenSnackBar("Please do not use offensive/vulgar in your tweet."));
            return false;
        }
        for (const image of images) {
            const formData = new FormData();
            formData.append("file", image.file);
            const { data } = await TweetApi.uploadTweetImage(formData);
            result.push(data);
        }

        result = result.flat();

        const taggedImageUsers = selectedUsers.map((user) => user.id);
        return {
            text,
            listId: tweetList?.id,
            gifImage: gif?.images.downsized,
            images: result,
            imageDescription,
            taggedImageUsers,
            replyType
        };
    };

    const tweetPostProcessing = (): void => {
        dispatch(resetAddTweetFormState());
        dispatch(resetUsersMentionsState());
        setText("");
        if (onCloseModal) onCloseModal();
    };

    
    // Debounced function to find the @mentions
    const findMentions = useCallback(
        debounce((inputText: string, cursor: number) => {
            // Find the last word that starts with @ and doesn't include a space
            const match = RegExp(/@\w*$/).exec(inputText.slice(0, cursor));
            if (match) {
                const query = match[0].substring(1); // remove the @
                if (query.length > 0) {
                    // Filter users by query
                    dispatch(resetUsersMentionsState());
                    dispatch(fetchUserMentionLists({ username: query.toLowerCase() }));
                }
            } 
        }, 300), []
    );

    const handleChangeText = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement>): void => {
            setAnchorEl(event.currentTarget);

            const inputText = event.target.value;
            setText(event.target.value);

            const cursor = event.target.selectionStart;
            setCursorPosition(cursor);

            // Call the debounced mention search function
            findMentions(inputText, cursor);
        },
        [dispatch]
    );

    // Handle selecting a suggestion
    const handleSelect = (username: string): void => {
        const beforeCursor = text.slice(0, cursorPosition);
        const afterCursor = text.slice(cursorPosition);

        // Replace the last @mention in the text with the selected username
        const newText = beforeCursor.replace(/@\w*$/, `@${username} `);
        setText(newText + afterCursor);

        dispatch(resetUsersMentionsState());
    };

    return (
        <>
            <div className={classes.content}>
                <ProfileAvatar />
                <div className={classes.textareaWrapper}>
                    <ScheduleDateInfo />
                    <TextareaAutosize
                        onChange={handleChangeText}
                        className={classes.contentTextarea}
                        placeholder={visiblePoll ? "Ask a question..." : title}
                        value={text}
                        maxRows={maxRows}
                        minRows={images.length !== 0 ? 1 : minRows}
                    />
                    <MentionsList usersMentionsList={usersMentionsList} handleSelect={handleSelect} />
                </div>
            </div>
            <div className={classes.formItems}>
                <AddTweetImage />
                {gif && <GifImage gifImage={gif.images.downsized} removeButton />}
                {quoteTweet && <Quote quoteTweet={quoteTweet} />}
                {tweetList && <TweetListComponent tweetList={tweetList} />}
                <Poll />
            </div>
            <Reply isUnsentTweet={!!unsentTweet} />
            <div className={classes.footer}>
                <div className={classes.footerWrapper}>
                    <UploadImages />
                    <GifIconButton />
                    <PollIconButton buttonName={buttonName} disabled={!!quoteTweet || scheduledDate !== null} />
                    <EmojiIconButton addEmoji={addEmoji} />
                    {/* <ScheduleIconButton buttonName={buttonName} disabled={!!quoteTweet} /> */}
                </div>
                <div className={classes.footerAddForm}>
                    <TextCountProgress text={text} />
                    <Button
                        onClick={
                            buttonName === "Reply"
                                ? handleClickReplyTweet
                                : quoteTweet !== undefined
                                ? handleClickQuoteTweet
                                : handleClickAddTweet
                        }
                        disabled={
                            visiblePoll ? (
                                !pollData.choice1 || !pollData.choice2 || !text || text.length > MAX_TEXT_LENGTH
                            ) : (
                              !text || text.length > MAX_TEXT_LENGTH
                            )}
                        color="primary"
                        variant="contained"
                    >
                        {buttonName}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default AddTweetForm;
